import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Grid,
  InputAdornment,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { faFolderBookmark } from "@fortawesome/pro-light-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { queryClient } from "@core";
import OptimizationResults from "@components/Optimization/Simulation/results";
import { SimulationOptions } from "@components/Optimization/SimulationOptions";
import alternativeCostStore from "@stores/AlternativeCost/AlternativeCost.store";
import { getConfigsForCollectionOptions } from "@stores/apis/Optimization/AlternativeCost.queries";
import OptimizationApi from "@stores/apis/Optimization/optimization.api";
import {
  getSimulationResultsOptions,
  getSimulationStatusOptions,
  startSimulationOptions,
} from "@stores/apis/Optimization/Optimization.queries";
import { NetworkStore } from "@stores/networks";
import SearchIcon from "@icons/Search";
import { useInject } from "@hooks";
import { BaseCard, FontAwesomeSvgIcon } from "@shared";

const objectToSnake_case = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => objectToSnake_case(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
      acc[newKey] = objectToSnake_case(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

const isJobRunning = (status: string) => ["in_progress", "init"].includes(status);

function ResultsView() {
  // Injects
  const { t } = useTranslation(["sales"]);

  const {
    alternativeCost,
    optimizationApi,
    networks,
  }: {
    alternativeCost: typeof alternativeCostStore;
    networks: NetworkStore;
    optimizationApi: OptimizationApi;
  } = useInject("alternativeCost", "optimizationApi", "networks", "routerStore", "notifications");

  // ----
  // Client State
  const networkUid = networks.current_network?.uid;
  const selectedCollection = alternativeCost.use.selectedCollection();
  const selectedConfig = alternativeCost.use.selectedConfiguration();
  const [startSimulation, setStartSimulation] = useState(false);

  // ---
  // Server State
  const {
    data: configurations,
    isLoading: configurationsLoading,
    isError: configurationsError,
  } = useQuery(getConfigsForCollectionOptions(optimizationApi, networkUid, selectedCollection?.id));

  const {
    data: jobId,
    isSuccess: simulationSuccess,
    isError: simulationError,
  } = useQuery(
    startSimulationOptions(optimizationApi, networkUid, selectedConfig?.id, startSimulation, {
      configId: selectedConfig?.id ?? 0,
      networkId: networkUid ?? "",
      networkName: networks.current_network?.name ?? "",
      // Start of the current year
      startTime: DateTime.local().startOf("year").toISO(),
      stopTime: DateTime.local().endOf("year").toISO(),
      // Last year
      trainingStart: DateTime.local().startOf("year").minus({ years: 1 }).toISO(),
      trainingStop: DateTime.local().endOf("year").minus({ years: 1 }).toISO(),
      stateInput: {},
      isScenario: true,
      isAltCost: true,
      makeOfficial: false,
    })
  );

  const {
    data: jobStatus,
    isError: jobStatusError,
    error: jobErrorMessage,
  } = useQuery(getSimulationStatusOptions(optimizationApi, networkUid, jobId));

  const {
    data: simulationResults,
    isSuccess: simulationResultsSuccess,
    // isError: simulationResultsError,
  } = useQuery(getSimulationResultsOptions(optimizationApi, networkUid, jobId, jobStatus));

  // ---
  // Effects

  useEffect(() => {
    if (configurations && configurations.length > 0 && !selectedConfig) {
      alternativeCost.set.selectedConfiguration(configurations[0]);
    }
  }, [configurations, selectedConfig, alternativeCost.set]);

  const canRun = useMemo(
    () =>
      !!configurations &&
      !configurationsError &&
      configurations.length > 0 &&
      !isJobRunning(jobStatus || ""),
    [configurations, configurationsError, jobStatus]
  );

  const simulationCallback = () => {
    // Clear any previous errors by invalidating the query
    void queryClient.invalidateQueries({
      queryKey: getSimulationStatusOptions(optimizationApi, networkUid, jobId).queryKey,
    });
    setStartSimulation(true);
  };

  useEffect(() => {
    if (simulationSuccess || simulationError || jobStatusError) {
      setStartSimulation(false);
    }
  }, [simulationSuccess, simulationError, jobStatusError]);

  return (
    <Box height="99%" display="flex">
      <Stack spacing={1} width="100%">
        <Grid
          container
          display="flex"
          height="100%"
          width="100%"
          alignItems="stretch"
          spacing={1}
          justifyContent="space-between"
        >
          <Grid item xs={3}>
            <Stack>
              <BaseCard
                minHeight={400}
                title={
                  <Grid display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2">{t("text_configurations")}</Typography>
                  </Grid>
                }
              >
                <Box sx={{ height: "100%", width: "100%" }}>
                  {(configurations || []).map((config) => (
                    <ListItemButton
                      key={config.id}
                      selected={config.id === selectedConfig?.id}
                      sx={{
                        width: "100%",
                        color: "primary.main",
                        border: 1,
                        borderColor: "divider",
                      }}
                      onClick={() => alternativeCost.set.selectedConfiguration(config)}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <FontAwesomeSvgIcon icon={faFolderBookmark} color="primary" />
                      </ListItemIcon>
                      <ListItem>
                        <Typography variant="body2">{config.name}</Typography>
                      </ListItem>
                    </ListItemButton>
                  ))}
                </Box>
              </BaseCard>
              <BaseCard
                title={
                  <Box width="100%" data-testid="opt-search-config">
                    <TextField
                      label={t("text_configuration_name")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Box>
                }
              />
            </Stack>
          </Grid>
          <Grid item xs>
            <BaseCard title={t("text_component_states")}>
              <Box>
                <Typography variant="h6">{}</Typography>
                {/* Render other configuration details as needed */}
                <Typography variant="body2">
                  {`${selectedConfig?.id} - ${selectedConfig?.name}` ||
                    "No detailed description available"}
                </Typography>
              </Box>
            </BaseCard>
          </Grid>
          <Grid item xs={3}>
            <SimulationOptions
              fetching={configurationsLoading}
              status={jobStatus}
              canRun={canRun}
              runSimulationCallback={simulationCallback}
            />
          </Grid>
        </Grid>
        {jobStatusError ? (
          <Alert severity="error">
            <AlertTitle>Job Error</AlertTitle>
            {jobErrorMessage.message}
          </Alert>
        ) : (
          isJobRunning(jobStatus || "") && <LinearProgress />
        )}

        {simulationResultsSuccess && simulationResults && (
          <OptimizationResults
            results={[objectToSnake_case(simulationResults)]}
            loading={isJobRunning(jobStatus || "")}
            components={[]}
          />
        )}
      </Stack>
    </Box>
  );
}

export default ResultsView;
