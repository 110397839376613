/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { darken } from "@mui/system";
import { DateTime } from "luxon";

import { toCamelCase } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { HighchartsBase } from "@shared/ui/analytics/charts";

import { ComponentType } from "../../../Opt.const";
import { darkenGraphColor, defaultPointFormatter } from "./chart_utils";

const HEIGHT = 720;
const unit = "kW";
const heatKeys = [
  { name: "text_heat_imported", key: "out", stack: "I", multiplier: -1 },
  { name: "text_heat_exported", key: "in", stack: "II", multiplier: 1 },
];

type SeriesData = {
  type: "area";
  name: string;
  data: number[];
  yAxis: number;
  stack: "I" | "II";
  pointInterval: number;
  cType: any;
  threshold: number;
  pointStart: number;
  color: string;
  tooltip: {
    pointFormatter: any;
    valueSuffix: string;
  };
  id: string;
  visible: boolean;
};

const ExternalConnectionGraph: React.FC<{ results: any }> = ({ results }) => {
  // @ts-expect-error lazy-loaded namespaces unrecognized
  const { t } = useTranslation(["production"]);
  const startTime =
    results && results.input_parameters && results.input_parameters.start_time
      ? DateTime.fromISO(results.input_parameters.start_time).toMillis()
      : 0;

  const [visibleSeries, setVisibleSeries] = useState<Record<string, boolean>>({});

  const createSeriesData = (
    comp: any["components"][number],
    i: number,
    componentList: any[]
  ): SeriesData[] => {
    return heatKeys.map((heatKey: any) => {
      const name = `${comp.name} ${t(heatKey.name)}`;
      const heatData = comp.variables[heatKey.key];
      return {
        type: "area",
        name,
        data: heatData.map((val: any) => val.current_value * heatKey.multiplier),
        yAxis: 0,
        stack: heatKey.stack,
        pointInterval: 3600000,
        cType: comp.type,
        threshold: 0,
        pointStart: startTime,
        color: darkenGraphColor({ component: comp, list: componentList, i }),
        tooltip: {
          pointFormatter: defaultPointFormatter,
          valueSuffix: unit,
        },
        id: toCamelCase(name),
        visible: true,
      };
    });
  };

  const series = useMemo(() => {
    const _series: SeriesData[] = [];
    const componentList: any[] = [];
    const newVisibleSeries: Record<string, boolean> = {};

    results?.components
      ?.filter((c: any) => c.type === ComponentType.EXTERNAL_CONNECTION)
      .forEach((comp: any, i: number) => {
        componentList.push(comp.type);
        const seriesData = createSeriesData(comp, i, componentList);
        seriesData.forEach((serie) => {
          newVisibleSeries[serie.id] = true;
          _series.push(serie);
        });
      });

    _series.forEach(({ cType }) => {
      if (cType === ComponentType.EXTERNAL_CONNECTION) {
        _series[1].color = darken(_series[1].color, 0.2);
      }
    });

    setVisibleSeries((prevVisibleSeries) => ({
      ...prevVisibleSeries,
      ...newVisibleSeries,
    }));

    return _series;
  }, [results]);

  const serieList = useMemo(() => {
    if (series.length === 0) return [];
    return series.map((serie) => {
      if (Object.hasOwn(visibleSeries, serie.id)) {
        return { ...serie, visible: visibleSeries[serie.id] };
      }
      return serie;
    });
  }, [visibleSeries, series]);

  const yAxis = () => {
    return [
      {
        title: {
          text: t("text_external_transferred_heat" as any),
        },
        yUnit: unit,
        resize: {
          enabled: true,
        },
        tickAmount: 7,
      },
    ];
  };

  return (
    <HighchartsBase
      series={serieList}
      chart={{
        type: "area",
        height: HEIGHT,
      }}
      plotOptions={{
        series: {
          marker: false,
          events: {
            legendItemClick: createSeriesToggleHandler(setVisibleSeries),
          },
        },
        area: {
          stacking: "normal",
        },
      }}
      xAxis={{
        title: {
          text: "Time",
        },
        type: "datetime",
      }}
      yAxisOptions={{
        resize: {
          enabled: true,
        },
      }}
      yAxis={yAxis}
      legend={{ enabled: true, layout: "horizontal", align: "center", verticalAlign: "bottom" }}
      disableBoost
    />
  );
};

export default observer(ExternalConnectionGraph);
