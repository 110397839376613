import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouterStore } from "mobx-state-router";
import { AppBar, IconButton, LinearProgress, Toolbar, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { faChevronsLeft } from "@fortawesome/pro-light-svg-icons";
import { useQuery } from "@tanstack/react-query";

import { AltCostCollection } from "@customTypes/production/sales";

import SectionSelector from "@components/ui/SectionSelector";
import alternativeCostStore from "@stores/AlternativeCost/AlternativeCost.store";
import { getNetworkCollectionsOptions } from "@stores/apis/Optimization/AlternativeCost.queries";
import OptimizationApi from "@stores/apis/Optimization/optimization.api";
import { NetworkStore } from "@stores/networks";
import { useEffectOnce, useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared";

import ConfigureView from "./ConfigureView";
import ResultsView from "./ResultsView";

const SECTION_CONFIGURE = "configure";
const SECTION_RESULTS = "results";

const SECTION = new Map([
  [SECTION_CONFIGURE, "text_configure"],
  [SECTION_RESULTS, "text_results"],
]);

function ViewCollection() {
  const [selectedTab, setSelectedTab] = useState(SECTION_CONFIGURE);
  const { t } = useTranslation(["sales"]);

  const {
    alternativeCost,
    optimizationApi,
    networks,
    routerStore,
  }: {
    alternativeCost: typeof alternativeCostStore;
    routerStore: RouterStore;
    networks: NetworkStore;
    optimizationApi: OptimizationApi;
  } = useInject("alternativeCost", "optimizationApi", "networks", "routerStore");

  // -----------------------------------------------------
  // State
  // -----------------------------------------------------
  const networkUid = networks.current_network?.uid;
  const selectedCollection = alternativeCost.use.selectedCollection();

  // -----------------------------------------------------
  // Queries
  // -----------------------------------------------------
  // Fetch the collections for the network
  const { data: collections } = useQuery(getNetworkCollectionsOptions(optimizationApi, networkUid));

  // Handle the cases where the store state is not set - ie when the user navigates directly to the page, and set the first configuration ID if available
  useEffect(() => {
    if (!selectedCollection && collections) {
      const id = Number(routerStore.routerState.params.collectionId);
      const theCollection = collections?.find((c: AltCostCollection) => c.id === id);
      if (theCollection) {
        alternativeCost.set.selectedCollection(theCollection);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection, collections, routerStore.routerState.params.collectionId]);

  useEffectOnce(() => {
    alternativeCost.set.resetConfig();
  });

  if (!selectedCollection) {
    return <LinearProgress />;
  }

  return (
    <Box height="100%">
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <IconButton
            onClick={() => routerStore.goTo("alt_cost")}
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <FontAwesomeSvgIcon icon={faChevronsLeft} />
          </IconButton>
          <Typography variant="h4" color="inherit" component="div">
            {t("text_collection")}: {selectedCollection?.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container width="100%" flexDirection="row">
        <SectionSelector
          selected={selectedTab}
          selectCallback={setSelectedTab}
          sections={[
            {
              title: t(SECTION.get(SECTION_CONFIGURE) as string),
              id: SECTION_CONFIGURE,
              component: ConfigureView,
              props: {
                collection: selectedCollection,
              },
              label: "",
            },
            {
              title: t(SECTION.get(SECTION_RESULTS) as string),
              id: SECTION_RESULTS,
              component: ResultsView,
              props: {
                collection: selectedCollection,
              },
              label: "",
            },
          ]}
        />
      </Grid>
    </Box>
  );
}

export default ViewCollection;
