import { FormProvider, useForm } from "react-hook-form";
import { TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";

import { NetworkStore } from "@stores/networks";
import { useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared";

export type GridFeesForm = {
  gridFeeFixed: number;
  gridMonthlyPowerFee: number;
  gridPeakPowerFee: number;
  gridElEnergyTransferStandardFee: number;
  gridElEnergyTransferPeakTimeFee: number;
};

export type AltCostGridFeesCardProps = {
  data: GridFeesForm;
  onChange: (data: GridFeesForm) => void;
};

const ElectricityGridFeesCard = ({ data, onChange }: AltCostGridFeesCardProps) => {
  const { t } = useTranslation(["sales"]);
  const theme = useTheme();

  const { networks } = useInject("alternativeCost", "networks") as { networks: NetworkStore };
  const currency = toJS(networks.currency);

  const methods = useForm<GridFeesForm>({
    defaultValues: data,
  });

  // Create field config to reduce repetition
  const fieldConfigs = [
    {
      name: "gridFeeFixed" as const,
      label: t("text_fixed_fee"),
      unit: `${currency}/month`,
    },
    {
      name: "gridMonthlyPowerFee" as const,
      label: t("text_monthly_power_fee"),
      unit: `${currency}/kW/month`,
    },
    {
      name: "gridPeakPowerFee" as const,
      label: t("text_peak_time_power_fee"),
      unit: `${currency}/kW/month`,
    },
    {
      name: "gridElEnergyTransferStandardFee" as const,
      label: t("text_electricity_enegy_transfer_standard_fee"),
      unit: `${currency}/kWh`,
    },
    {
      name: "gridElEnergyTransferPeakTimeFee" as const,
      label: t("text_electricity_enegy_transfer_peak_time_fee"),
      unit: `${currency}/kWh`,
    },
  ];

  const handleChange = (name: string, value: string) => {
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      const updatedData = {
        ...data,
        [name]: numValue,
      } as GridFeesForm;
      onChange(updatedData);
    }
  };

  return (
    <FormProvider {...methods}>
      <Stack spacing={1} display="flex" p={2} alignItems="center" sx={{ width: "100%" }}>
        {fieldConfigs.map(({ name, label, unit }) => (
          <Stack
            key={name}
            direction="row"
            width="100%"
            justifyContent="start"
            alignItems="center"
            spacing={2}
          >
            <TextFieldElement
              name={name}
              label={label}
              control={methods.control}
              size="small"
              fullWidth
              type="number"
              variant="standard"
              onChange={(e) => handleChange(name, e.target.value)}
              InputProps={{
                endAdornment: <Typography>{unit}</Typography>,
              }}
            />
            <FontAwesomeSvgIcon
              icon={faQuestionCircle}
              sx={{ color: theme.palette.primary.main, fontSize: 14 }}
            />
          </Stack>
        ))}
      </Stack>
    </FormProvider>
  );
};

export default ElectricityGridFeesCard;
