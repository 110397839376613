import { createStore } from "zustand-x";

type AuthStore = {
  token: string | null;
};

const authStore = createStore<AuthStore>(
  {
    token: null,
  },
  {
    name: "AuthStore",
  }
);
// Actions set state, Selectors is for custom parsing of state
export default authStore;
