import { useFeatureFlagEnabled as usePosthogFeatureFlagEnabled } from "posthog-js/react";

/**
 * Hook to check if a feature flag is enabled.
 * Either returns the PostHog hook, or a mock hook that checks the environment variables.
 * This gives us the ability to test feature flags in E2E tests. And also override the feature flags in the environment.
 */
export const useFeatureFlagEnabled =
  import.meta.env?.["REACT_APP_PLAYWRIGHT_RUN"] === "true"
    ? (flag: string) => import.meta.env?.["REACT_APP_FEATURES"]?.split(",").includes(flag)
    : usePosthogFeatureFlagEnabled;
