import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import {
  Alert,
  Button,
  InputAdornment,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { OptComponent, OptConfig } from "@customTypes/production/optimization";
import { AltCostCollection, AlternativeCostConfigForm } from "@customTypes/production/sales";

import alternativeCostStore from "@stores/AlternativeCost/AlternativeCost.store";
import { fnCalculateComponents } from "@stores/AlternativeCost/AlternativeCost.util";
import {
  getAltCostComponentTypes,
  getAltCostConfigComponents,
  getConfigsForCollectionOptions,
} from "@stores/apis/Optimization/AlternativeCost.queries";
import OptimizationApi from "@stores/apis/Optimization/optimization.api";
import { NetworkStore } from "@stores/networks";
import NotificationStore from "@stores/Notification/notification.store";
import SearchIcon from "@icons/Search";
import { useInject } from "@hooks";
import { BaseCard } from "@shared";

import ConfigureForm from "./ConfigureForm";
import ElectricityGridFeesCard, { GridFeesForm } from "./ElectricityGridFeesCard";
import ElectricityTradePriceCard from "./ElectricityTradePriceCard";
import WeatherCard from "./WeatherCard";

function ConfigureView() {
  const { t } = useTranslation(["sales"]);

  const queryClient = useQueryClient();

  const {
    alternativeCost,
    optimizationApi,
    networks,
    notifications,
  }: {
    alternativeCost: typeof alternativeCostStore;
    networks: NetworkStore;
    optimizationApi: OptimizationApi;
    notifications: NotificationStore;
  } = useInject("alternativeCost", "optimizationApi", "networks", "notifications");

  // -----------------------------------------------------
  // State
  // -----------------------------------------------------
  const [calculatedComponents, setCaculatedComponents] = useState<OptComponent[]>([]);
  const networkUid = networks.current_network?.uid;
  const selectedCollection = alternativeCost.use.selectedCollection();
  const selectedConfig = alternativeCost.use.selectedConfiguration();
  const selectedConfigComponents = alternativeCost.use.selectedConfigComponents();
  const configForm = alternativeCost.use.configForm();

  // -----------------------------------------------------
  // Queries
  // -----------------------------------------------------
  // Fetch the configurations for the selected collection
  const listConfigurationsOptions = getConfigsForCollectionOptions(
    optimizationApi,
    networkUid,
    selectedCollection?.id
  );

  const {
    data: configurations,
    isLoading: configurationsLoading,
    isError: configurationsError,
  } = useQuery(listConfigurationsOptions);

  // Fetch the component types for the network
  const networkComponentTypes = getAltCostComponentTypes(optimizationApi, networkUid);
  const { data: componentTypes } = useQuery(networkComponentTypes);

  const { data: configComponents } = useQuery(
    getAltCostConfigComponents(optimizationApi, networkUid, selectedConfig?.id)
  );

  // ----
  // Mutations
  // ----
  // Mutation to create a new configuration
  const { mutate: createAltCostConfiguration } = useMutation({
    mutationFn: async () => {
      if (!networkUid || !selectedCollection?.id) {
        throw new Error("Network UID not set");
      }
      // Reset
      alternativeCost.set.resetConfig();
      setCaculatedComponents([]);

      return optimizationApi.createAltCostConfiguration(networkUid, selectedCollection.id);
    },

    onSuccess: async (config) => {
      notifications.success(`Configuration "${config.name}" created`);
      alternativeCost.set.selectedConfiguration(config);
      await queryClient.invalidateQueries({
        queryKey: listConfigurationsOptions.queryKey,
      });
    },

    onError: (error) => {
      notifications.error(`Failed to create configuration: ${error.message}`);
    },
  });

  // Upsert component
  const upsertComponent = useMutation({
    mutationFn: async (component: OptComponent) => {
      if (!selectedConfig) {
        throw new Error("No configuration selected");
      }
      if (!networkUid) {
        throw new Error("Network UID not set");
      }

      if (component.id) {
        return optimizationApi.updateComponent(
          networkUid,
          selectedConfig.id,
          component.id,
          component
        );
      }
      return optimizationApi.createComponent(networkUid, selectedConfig.id, component);
    },
    onError: (error) => {
      notifications.error(`Failed to update component: ${error.message}`);
    },
  });

  // -----------------------------------------------------
  // Functions
  // -----------------------------------------------------
  const onFormChange = useCallback(
    (values: AlternativeCostConfigForm) => {
      // Build componenets from values
      alternativeCost.set.configForm(values);
      alternativeCost.set.componentTypes(componentTypes || []);
      if (selectedConfigComponents && componentTypes) {
        const newComponents = fnCalculateComponents(
          values,
          selectedConfigComponents,
          componentTypes
        );
        if (JSON.stringify(newComponents) !== JSON.stringify(calculatedComponents)) {
          setCaculatedComponents(newComponents);
          return;
        }
      }
      setCaculatedComponents([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [componentTypes, selectedConfigComponents]
  );

  const onWeatherUpdate = (values: number[], name: string) => {
    if (!selectedCollection) return;

    const newData = {
      ...selectedCollection,
      weatherData: values,
      weatherDataDescription: name,
    };
    if (selectedCollection) {
      alternativeCostStore.set.selectedCollection(newData);
    }

    updateCollection.mutate(newData);
  };

  const onElectricityPriceUpdate = (values: number[], name: string, value: number) => {
    if (!selectedCollection) return;

    const newData = {
      ...selectedCollection,
      electricityPrice: values,
      electricityPriceDescription: name,
      electricityPriceAddon: value,
    };

    if (selectedCollection) {
      alternativeCostStore.set.selectedCollection(newData);
    }

    updateCollection.mutate(newData);
  };

  const onGridFeesUpdate = (values: GridFeesForm) => {
    if (!selectedCollection) return;

    const newData = {
      ...selectedCollection,
      ...values,
    };

    if (selectedCollection) {
      alternativeCostStore.set.selectedCollection(newData);
    }
    updateCollection.mutate(newData);
  };

  const updateCollection = useMutation({
    mutationFn: async (collection: AltCostCollection) => {
      if (!networkUid) {
        throw new Error("Network UID not set");
      }
      return optimizationApi.updateCollection(networkUid, collection.id, collection);
    },
    onError: (error) => {
      notifications.error(`Failed to update collection: ${error.message}`);
    },
  });

  useEffect(() => {
    if (!selectedConfig) return;

    // Upsert the components if the calculated components change
    const mutations = calculatedComponents.map((component) =>
      upsertComponent.mutateAsync(component)
    );

    if (!mutations.length) return;

    // Invalidate the query when the mutations are done
    void Promise.all(mutations).then(() => {
      return queryClient.invalidateQueries({
        queryKey: getAltCostConfigComponents(optimizationApi, networkUid, selectedConfig?.id)
          .queryKey,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatedComponents, networkUid, selectedConfig]);

  const onConfigChange = (config: OptConfig) => {
    if (config.id === selectedConfig?.id) return;
    alternativeCost.set.resetConfig(config);
    setCaculatedComponents([]);
  };

  useEffect(() => {
    // Use this to calculate the initial form values
    alternativeCost.set.selectedConfigComponents(configComponents || []);
    if (configComponents && !configForm) {
      alternativeCost.set.newFormValues();
    }
  }, [configComponents, alternativeCost, configForm]);

  const weatherData = {
    values: selectedCollection?.weatherData || [],
    name: selectedCollection?.weatherDataDescription || "",
  };

  const electricityPriceData = {
    values: selectedCollection?.electricityPrice || [],
    name: selectedCollection?.electricityPriceDescription || "",
    value: selectedCollection?.electricityPriceAddon || 0,
  };

  const electricityGridFeesData = {
    gridFeeFixed: selectedCollection?.gridFeeFixed || 0,
    gridMonthlyPowerFee: selectedCollection?.gridMonthlyPowerFee || 0,
    gridPeakPowerFee: selectedCollection?.gridPeakPowerFee || 0,
    gridElEnergyTransferStandardFee: selectedCollection?.gridElEnergyTransferStandardFee || 0,
    gridElEnergyTransferPeakTimeFee: selectedCollection?.gridElEnergyTransferPeakTimeFee || 0,
  };

  return (
    <Grid
      container
      spacing={2}
      p={2}
      height="100%"
      flexDirection="column"
      justifyContent="flex-start"
      wrap="nowrap"
    >
      {configurationsLoading ? <LinearProgress /> : null}
      {configurationsError ? (
        <Alert severity="error">{t("text_error_handling_configurations")}</Alert>
      ) : null}
      {/* Weather, El and El Grid fees Boxes */}
      <Grid
        item
        container
        xs={12}
        minHeight={300}
        spacing={2}
        flexDirection="row"
        wrap="wrap"
        height="30%"
      >
        <Grid item xs={4} minWidth={300} data-testid="weather-card">
          <BaseCard title="Weather">
            <WeatherCard data={weatherData} onChange={onWeatherUpdate} />
          </BaseCard>
        </Grid>
        <Grid item xs={4} minWidth={300} data-testid="electricity-price-card">
          <BaseCard title="Electricity Trade Price">
            <ElectricityTradePriceCard
              data={electricityPriceData}
              onChange={onElectricityPriceUpdate}
            />
          </BaseCard>
        </Grid>
        <Grid item xs={4} minWidth={300} data-testid="electricity-grid-fees-card">
          <BaseCard title="Electricity Grid Fees">
            <ElectricityGridFeesCard data={electricityGridFeesData} onChange={onGridFeesUpdate} />
          </BaseCard>
        </Grid>
      </Grid>
      {/* Configurations here */}
      <Grid
        item
        xs={12}
        container
        spacing={2}
        pb={2}
        flexDirection="row"
        justifyContent="flex-start"
        alignContent="stretch"
      >
        {/* Configurations */}
        <Grid item xs={4} height="100%">
          <BaseCard
            minHeight={400}
            title={
              <Grid display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body2">{t("text_configurations")}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => createAltCostConfiguration()}
                >
                  {t("text_add_configuration")}
                </Button>
              </Grid>
            }
          >
            <Box sx={{ height: "100%", width: "100%" }}>
              {(configurations || [])
                .sort((a, b) => a.id - b.id)
                .map((config) => (
                  <ListItemButton
                    key={config.id}
                    selected={config.id === selectedConfig?.id}
                    sx={{
                      width: "100%",
                      color: "primary.main",
                      border: 1,
                      borderColor: "divider",
                    }}
                    onClick={() => onConfigChange(config)}
                  >
                    <ListItemIcon sx={{ minWidth: 0 }}>
                      <PhotoOutlinedIcon color="primary" />
                    </ListItemIcon>
                    <ListItem>
                      <Typography variant="body2">{config.name}</Typography>
                    </ListItem>
                    <MoreHorizOutlinedIcon color="primary" />
                  </ListItemButton>
                ))}
            </Box>
          </BaseCard>
          <BaseCard
            title={
              <Box width="100%" data-testid="opt-search-config">
                <TextField
                  label={t("text_configuration_name")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Box>
            }
          />
        </Grid>
        {/* Configurations Details */}
        <Grid item xs={8}>
          <BaseCard title={t("text_configuration_details")}>
            {
              // If no configuration is selected, show a message
              selectedConfig && configForm ? (
                <ConfigureForm configValues={configForm} onChange={onFormChange} />
              ) : (
                <Typography>{t("text_select_configuration")}</Typography>
              )
            }
          </BaseCard>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ConfigureView;
