import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { DateTime } from "luxon";

import { formatNumberForLocale, toCamelCase } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { useInject } from "@hooks";
import { HighchartsBase } from "@shared/ui/analytics/charts";

import { darkenGraphColor, defaultPointFormatter } from "./chart_utils";

const HEIGHT = 720;

function currencyPointFormatter() {
  const { color, series, y } = this;
  return `<span style="color:${color}">●</span> ${series.name}:<b> ${formatNumberForLocale(y)}${
    series.userOptions.tooltip.valueSuffix
  }</b><br/>`;
}

function ElectricityPriceGraph({ results }) {
  const { t } = useTranslation(["production"]);
  const { networks } = useInject("networks");

  const [visibleSeries, setVisibleSeries] = useState({});

  const series = useMemo(() => {
    const startTime = DateTime.fromISO(results.input_parameters.start_time).toMillis();

    const result = [];
    const componentList = [];
    const newVisibleSeries = {};
    results.components.forEach((component, i) => {
      // If the component consumes electricity, add to the series
      // This is stored on the component kpi
      if (component.kpis?.electricity_consumed) {
        componentList.push(component.type);
        const name = `${component.name} electricity consumed`;
        newVisibleSeries[toCamelCase(name)] = true;
        result.push({
          name,
          stack: "I",
          data: Object.values(component.kpis.electricity_consumed).map((v) => v * -1) || [],
          type: "area",
          pointInterval: 3600000,
          pointStart: startTime,
          yAxis: 1,
          threshold: 0,
          color: darkenGraphColor({ component, list: componentList, i }),
          tooltip: {
            pointFormatter: defaultPointFormatter,
            valueSuffix: "kW",
          },
          id: toCamelCase(name),
          visible: true,
        });
      }

      // If the component produces electricity, add to the series
      // This is stored on the component kpi
      if (component.kpis?.electricity_produced) {
        componentList.push(component.type);
        const name = `${component.name} electricity produced`;
        newVisibleSeries[toCamelCase(name)] = true;
        result.push({
          name,
          stack: "II",
          data: Object.values(component.kpis.electricity_produced) || [],
          type: "area",
          pointInterval: 3600000,
          pointStart: startTime,
          yAxis: 1,
          threshold: 0,
          color: darkenGraphColor({ component, list: componentList, i }),
          tooltip: {
            pointFormatter: defaultPointFormatter,
            valueSuffix: "kW",
          },
          id: toCamelCase(name),
          visible: true,
        });
      }

      // Add the component electricity price parameter if it exists
      if (component.parameters?.el_price && Object.keys(component.parameters.el_price).length > 0) {
        componentList.push(component.type);
        const name = `${component.name} electricity price`;
        newVisibleSeries[toCamelCase(name)] = true;
        result.push({
          type: "line",
          name,
          yAxis: 0,
          pointInterval: 3600000,
          pointStart: startTime,
          data: Object.values(component.parameters.el_price) || [],
          threshold: 0,
          color: darkenGraphColor({ component, list: componentList, i }),
          tooltip: {
            pointFormatter: currencyPointFormatter,
            valueSuffix: ` ${networks.currency}/kWh`,
          },
          id: toCamelCase(name),
          visible: true,
        });
      }
    });

    setVisibleSeries((prevVisibleSeries) => ({
      ...prevVisibleSeries,
      ...newVisibleSeries,
    }));
    return result;
  }, [networks.currency, results.components, results.input_parameters.start_time]);

  const serieList = useMemo(() => {
    if (series.length === 0) return [];

    return series.map((serie) => {
      if (Object.hasOwn(visibleSeries, serie.id)) {
        return {
          ...serie,
          visible: visibleSeries[serie.id],
          dataGrouping: {
            enabled: true,
            forced: true,
            units: [["hour", [1, 24]]],
          },
        };
      }
      return serie;
    });
  }, [visibleSeries, series]);

  const yAxis = useMemo(
    () => [
      {
        title: {
          text: t("text_electricity_buy_sell_price"),
        },
        yUnit: `${networks.currency}/kWh`,
        resize: {
          enabled: true,
        },
      },
      {
        title: {
          text: t("text_electricity_production_consumption"),
        },
        yUnit: "kW",
        resize: {
          enabled: true,
        },
      },
    ],
    [networks.currency, t]
  );

  return (
    <HighchartsBase
      chart={{
        type: "area",
        height: HEIGHT,
        alignThresholds: true,
      }}
      xAxis={{
        title: {
          text: "Time",
        },
        type: "datetime",
      }}
      yAxis={yAxis}
      series={serieList}
      plotOptions={{
        series: {
          marker: false,
          events: {
            legendItemClick: createSeriesToggleHandler(setVisibleSeries),
          },
        },
        area: {
          stacking: "normal",
        },
      }}
      legend={{ enabled: true, layout: "horizontal", align: "center", verticalAlign: "bottom" }}
      disableBoost
    />
  );
}

export default observer(ElectricityPriceGraph);
