import { useEffect, type EffectCallback } from "react";

/**
 * A shortcut hook for useEffect with empty [] dependency array
 * it helps to reproduce behaviour of "componentDidMount" and
 * avoids repeatedly disabling "react-hooks/exhaustive-deps"
 *
 * @param effectCallback Imperative function that can return a cleanup function
 */
export const useEffectOnce = (effectCallback: EffectCallback) => {
  useEffect(effectCallback, []);
};
