/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosDefaults, CreateAxiosDefaults } from "axios";

import { toCamelCase } from "../utils/toCamelCase";
import { toSnakeCase } from "../utils/toSnakeCase";

const BASE_URL = import.meta.env?.["REACT_APP_MDSL_V4_URL"];

type CustomAxiosClientConfig = {
  shouldCamelCaseResponse: boolean;
} & CreateAxiosDefaults;

export type CustomAxiosDefaults = {
  shouldCamelCaseResponse?: boolean;
} & AxiosDefaults<any>;

export const createAxiosClient = (baseURL: string, shouldCamelCaseResponse = false) => {
  const client = axios.create({
    baseURL: baseURL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    shouldCamelCaseResponse,
  } as CustomAxiosClientConfig);

  // Middleware goes here
  // Add a response interceptor and camelCase return data (for JS)
  client.interceptors.response.use(
    (response) => {
      if ((response.config as CustomAxiosClientConfig).shouldCamelCaseResponse === false)
        return response;

      response.data = toCamelCase(response.data);
      return response;
    },
    (error) => {
      if (error.response.config.shouldCamelCaseResponse === false) return Promise.reject(error);

      if (error.response) {
        if (error.response.config.shouldCamelCaseResponse === false) return error;

        error.response.data = toCamelCase(error.response.data);
      } else {
        error.response = { data: null };
      }
      return Promise.reject(error);
    }
  );

  // Add a request interceptor and snakeCase POST data (for django)
  client.interceptors.request.use(
    (config) => {
      if ((config as CustomAxiosClientConfig).shouldCamelCaseResponse === false) return config;

      config.params = toSnakeCase(config.params);
      config.data = toSnakeCase(config.data);
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return client;
};

const axiosClient = createAxiosClient(BASE_URL);

export default axiosClient;
