import { AlternativeCostConfigForm } from "@customTypes/production/sales";

export const calculateDemands = (
  weatherData: number[],
  configForm: AlternativeCostConfigForm
): Record<string, number> => {
  // Here we calculate the values based on the config
  let maxHeatPower = 0;
  let radiatorHeatKwh = 0;
  let hotWaterHeatKwh = 0;
  let radiatorSpecificHeatKwh = 0;
  let hotWaterSpecificHeatKwh = 0;
  let installedHeatPumpCapacity = 0;
  let installedPeakBoilerCapacity = 0;

  // Calculate heat demand for each hour
  weatherData.forEach((temp) => {
    // Hot water use is constant all hours and equals the energy signature output at t_0
    const hotWaterForHour =
      configForm.energySignatureA * configForm.energySignatureT0 + configForm.energySignatureB;

    // Space heating demand is calculated via energy signature
    // We only consider space heating demand if the outdoor temperature is below t_0
    let radiatorHeatForHour = 0;
    if (temp < configForm.energySignatureT0) {
      radiatorHeatForHour =
        configForm.energySignatureA * temp + configForm.energySignatureB - hotWaterForHour;
    }

    hotWaterHeatKwh += hotWaterForHour;
    radiatorHeatKwh += radiatorHeatForHour;

    // Calculate max heat power
    maxHeatPower = Math.max(maxHeatPower, hotWaterForHour + radiatorHeatForHour);
  });

  radiatorSpecificHeatKwh = radiatorHeatKwh / (configForm.heatedArea || 1);
  hotWaterSpecificHeatKwh = hotWaterHeatKwh / (configForm.heatedArea || 1);

  // Calculate installed heat pump capacity
  installedHeatPumpCapacity = maxHeatPower * (configForm.powerCoverage / 100);

  // Calculate installed peak boiler capacity
  installedPeakBoilerCapacity = maxHeatPower - installedHeatPumpCapacity;

  return {
    maxHeatPower,
    radiatorHeatKwh,
    hotWaterHeatKwh,
    radiatorSpecificHeatKwh,
    hotWaterSpecificHeatKwh,
    installedHeatPumpCapacity,
    installedPeakBoilerCapacity,
  };
};
