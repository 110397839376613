import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Alert, Box, Button, Divider, LinearProgress, Typography } from "@mui/material";
import { DateTime } from "luxon";

import type { OptComponent, OptDeviationEvent } from "@customTypes/production/optimization";

import { DATETIME_FORMAT_DEFAULT } from "@conf/constants";
import UtfAccordion from "@shared/ui/UtfAccordion";

function groupEventsByDay(
  deviations: OptDeviationEvent[],
  thresholdDate: DateTime,
  future = false
) {
  if (!thresholdDate || !thresholdDate.toISODate()) return {};
  return (deviations || [])
    .filter((item) => {
      // Exclude events with no start date - This should never happen
      if (!item.start_date) return false;

      // filter out events based on the threshold date
      // Events missing an end date are considered ongoing forever
      // Ongoing events are considered future events
      if (future) {
        return (
          item.start_date >= thresholdDate.toISODate()! ||
          (!item.end_date && item.start_date) ||
          item.end_date! > thresholdDate.toISODate()!
        );
      } else {
        return item.start_date < thresholdDate.toISODate()! && item.end_date;
      }
    })
    .reduce((acc: { [key: string]: OptDeviationEvent[] }, event: OptDeviationEvent) => {
      // Extract the start date from the event and remove the time component
      const startDate = event.start_date.split("T")[0];
      if (!acc[startDate]) {
        acc[startDate] = [];
      }
      // Add the event to the array for the start date
      acc[startDate].push(event);
      return acc;
    }, {});
}

function DeviationRow({
  event,
  component,
  handleViewEvent,
  loading,
}: {
  event: OptDeviationEvent;
  component?: OptComponent;
  handleViewEvent?: Props["viewEvent"];
  loading?: boolean;
}) {
  const { t } = useTranslation(["production", "_common"]);
  if (!component) return null;

  const { disabled, name, comment, start_date: startDate, end_date: endDate } = event;

  return (
    <UtfAccordion
      key={`event-${event.id}`}
      data-testid={`event-${event.id}`}
      disabled={loading}
      header={
        <>
          <Box sx={{ minWidth: "30%" }}>{name}</Box>
          <Box sx={{ flex: 1 }}>{component.name}</Box>
          <Typography
            variant="caption"
            color="grey.blue50"
            lineHeight="inherit"
            sx={{ flex: 1, overflow: "hidden" }}
          >
            {DateTime.fromISO(startDate).toFormat(DATETIME_FORMAT_DEFAULT)} -{" "}
            {endDate
              ? DateTime.fromISO(endDate).toFormat(DATETIME_FORMAT_DEFAULT)
              : "[until further notice]"}
          </Typography>
          {handleViewEvent && (
            <Box>
              <Button
                variant="text"
                onClick={() => handleViewEvent(component, event)}
                data-testid="view-event"
              >
                {t("action_view_event")}
              </Button>
            </Box>
          )}
        </>
      }
      headerReversed
      segmented
    >
      <Typography
        variant="body1"
        fontWeight={disabled ? "bold" : "normal"}
        sx={{ maxWidth: "30%" }}
      >
        {t(disabled ? "action_component_disabled" : "action_component_enabled")}
      </Typography>
      <Typography variant="body1" fontWeight="normal" fontStyle="italic">
        {comment}
      </Typography>
    </UtfAccordion>
  );
}
type Props = {
  deviations: OptDeviationEvent[];
  components: OptComponent[];
  loading?: boolean;
  viewEvent?: (component: OptComponent, event: OptDeviationEvent) => void;
  showHistorical?: boolean;
};
const DeviationsSummaryTable = observer(
  ({ deviations, components, loading, viewEvent: handleViewEvent, showHistorical }: Props) => {
    const { t } = useTranslation(["production", "_common"]);

    const now = DateTime.now();
    /**
     * Filters and groups events by day based on their start and end dates
     * @param {Array<OptDeviationEvent>} deviations - An array of event objects
     * @returns {object} An object where each key is a start date (formatted as YYYY-MM-DD) and
     * each value is an array of events that start on that day
     */
    const pastGroupedEvents = showHistorical ? groupEventsByDay(deviations, now) : {};
    const futureGroupedEvents = groupEventsByDay(deviations, now, true);

    const pastEvents: { day: string; events: OptDeviationEvent[] }[] = Object.entries(
      pastGroupedEvents
    )
      .sort(([date1], [date2]) => date1.localeCompare(date2))
      .map(([day, events]: [string, OptDeviationEvent[]]) => ({
        day: DateTime.fromISO(day).toFormat("dd LLL"),
        events,
      }));

    const futureEvents = Object.entries(futureGroupedEvents)
      .sort(([date1], [date2]) => date1.localeCompare(date2))
      .map(([day, events]: [string, OptDeviationEvent[]]) => ({
        day: DateTime.fromISO(day).toFormat("dd LLL"),
        events,
      }));
    const groupEventsByDayArr = pastEvents.concat(futureEvents);

    return (
      <Box p={2} width="100%">
        <div>
          {/* Loading */}
          {loading && <LinearProgress data-testid="loading-indicator" />}
          {/* No record */}
          {groupEventsByDayArr.length === 0 && !loading && (
            <Box p={2} width="100%">
              <Alert severity="info" data-testid="no-events">
                {t("text_no_events_found")}
              </Alert>
            </Box>
          )}
        </div>
        {/* Events Grouped by Day - Past */}
        {pastEvents.map(({ day, events: eventsInDay }) => (
          <Box mb={2} key={day} width="100%">
            <Typography component={Box} variant="caption" color="secondary.main" mb={0.5}>
              {day}
            </Typography>
            {eventsInDay.map((event) => (
              <DeviationRow
                key={`event-${event.id}`}
                event={event}
                // eslint-disable-next-line eqeqeq -- we need to fix int vs string comparison
                component={components.find((c) => c.id == event.component_id)}
                handleViewEvent={handleViewEvent}
                loading={loading}
              />
            ))}
          </Box>
        ))}
        {pastEvents.length > 0 && futureEvents.length > 0 && (
          <Box mb={2} width="100%">
            <Divider />
          </Box>
        )}
        {/* Events Grouped by Day - Future */}
        {futureEvents.map(({ day, events: eventsInDay }) => (
          <Box mb={2} key={day} width="100%">
            <Typography component={Box} variant="caption" color="secondary.main" mb={0.5}>
              {day}
            </Typography>
            {eventsInDay.map((event) => (
              <DeviationRow
                key={`event-${event.id}`}
                event={event}
                // eslint-disable-next-line eqeqeq -- we need to fix int vs string comparison
                component={components.find((c) => c.id == event.component_id)}
                handleViewEvent={handleViewEvent}
                loading={loading}
              />
            ))}
          </Box>
        ))}
      </Box>
    );
  }
);

export default DeviationsSummaryTable;
