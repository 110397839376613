import { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { DateTime } from "luxon";

import type {
  OptComponentKpis,
  OptResultComponent,
  OptResultConfig,
} from "@customTypes/production/optimization";

import { INTERVAL_DAILY, INTERVAL_HOURLY } from "@config";
import { formatNumberForLocale, toCamelCase } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { HighchartsBase } from "@shared/ui/analytics/charts";

import {
  ComponentType,
  COOLING_PRODUCING_COMPONENTS,
  DEMAND_COMPONENTS,
  HEAT_DEMAND_COMPONENTS,
  HEAT_PRODUCING_COMPONENTS,
} from "./Opt.const";
import { darkenGraphColor, defaultPointFormatter } from "./Simulation/results/charts/chart_utils";

const HEIGHT = 720;
const unit = "kW";

function getEnergyProducedSeries(
  component: OptResultComponent,
  demandType: OptComponentKpis
): {
  standardEnergy: number[];
  bypass: number[];
} {
  let energyProduced = [];
  const bypassOut: number[] = [];
  // If the component has the demandType kpi, use that, otherwise use the variable
  if (component.kpis[demandType]) {
    energyProduced = Object.values(component.kpis[demandType]);
  } else {
    // This is for components that have heat, but not heat_produced
    // Like the external connection
    energyProduced = component.variables.out.map((value) => value.current_value);
  }
  return { standardEnergy: energyProduced, bypass: bypassOut };
}

function getEnergyRequiredSeries(
  component: OptResultComponent,
  demandType: "demand" | "cooling_demand"
): number[] {
  let demand = [];
  if (component.parameters[demandType]) {
    demand = Object.values(component.parameters[demandType] || {});
  } else {
    // This is for components that have heat required, but no demand
    // Like the external connection
    demand = component.variables.in.map((value) => value.current_value);
  }
  return demand;
}

function createSeries({
  i = 0,
  component,
  sortValue,
  data,
  startTime,
  type,
  componentList,
  isVisible = true,
}: {
  i: number;
  component: Partial<OptResultComponent>;
  sortValue: number;
  data: number[];
  startTime: number;
  type: string;
  componentList: string[];
  isVisible?: boolean;
}) {
  const name = component.name ?? "";
  const series = {
    name,
    type,
    yAxis: 0,
    pointInterval: 3600000,
    pointStart: startTime,
    data,
    visible: isVisible,
    color: darkenGraphColor({ component, list: componentList, i }),
    tooltip: {
      pointFormatter: defaultPointFormatter,
      valueSuffix: unit,
    },
    id: toCamelCase(name),
  };

  return {
    series,
    sortValue,
  };
}

function getSortValue(valueArr: number[], maxValue: number) {
  const total = valueArr.reduce((a, b) => a + b, 0) / valueArr.length;
  return total / maxValue;
}

function EnergyProductionGraph({
  results,
  validTypes,
  loading,
  overrideDemandType,
  averaged = false,
}: {
  results: OptResultConfig;
  validTypes: string[];
  loading: boolean;
  overrideDemandType?: string;
  averaged?: boolean;
}) {
  const demandType =
    overrideDemandType ||
    (validTypes === HEAT_PRODUCING_COMPONENTS ? "heat_produced" : "cooling_produced");
  const demandParameter = validTypes === HEAT_PRODUCING_COMPONENTS ? "demand" : "cooling_demand";
  const startTime = DateTime.fromISO(results?.input_parameters.start_time).toMillis();
  const [visibleSeries, setVisibleSeries] = useState<{ [key: string]: boolean }>({});

  /**
   * This function finds the max production of a component, based on the type of component.
   */
  const getMaxProduction = useCallback(
    (component: OptResultComponent | undefined): number => {
      if (!component) {
        return 0;
      }
      let maxProduction = 0;
      let valueArr = [];
      if (component.kpis[demandType]) {
        valueArr = Object.values(component.kpis[demandType]);
      } else {
        // This is for components that have heat, but not heat_produced
        // Like the external connection
        valueArr = component.variables.out.map((value) => value.current_value);
      }
      // Find the max value in the array
      maxProduction = valueArr.reduce((a, b) => Math.max(a, b), 0);
      return maxProduction;
    },
    [demandType]
  );

  const totalSeries = useMemo(() => {
    const series = [];
    const seriesWrappers = [];
    let CHPBypassEnergy: number[] = [];
    const CPType: string[] = [];

    // Helper function to create series wrappers for components
    const createComponentSeries = (component: any, i: any) => {
      const maxProduction = getMaxProduction(component);
      const energyObj = getEnergyProducedSeries(component, demandType);
      const energyProduced = energyObj.standardEnergy;

      if (component.type === ComponentType.CHP) {
        CHPBypassEnergy = energyObj.bypass;
      }
      CPType.push(component.type);
      return createSeries({
        i,
        component,
        sortValue: getSortValue(energyProduced, maxProduction),
        data: energyProduced,
        startTime,
        type: "area",
        componentList: CPType,
      });
    };

    if (!results?.components) return [];

    // Add Heat or Cooling Production components and CHP bypass energy
    results.components
      .filter((x) => (x.kpis && x.kpis[demandType]) || x.type === ComponentType.EXTERNAL_CONNECTION)
      .forEach((component, i) => {
        const seriesWrapper = createComponentSeries(component, i);
        seriesWrappers.push(seriesWrapper);
      });

    // Add CHP bypass energy to the graph if exists
    if (CHPBypassEnergy.length > 0) {
      const chpComponent = results.components.find(
        (component) => component.type === ComponentType.CHP
      );
      const chpMax = getMaxProduction(chpComponent);
      const bypassSort = getSortValue(CHPBypassEnergy, chpMax);
      const bypassWrapper = createSeries({
        i: 0,
        component: { name: "CHP_bypass", type: "CHP" },
        sortValue: bypassSort,
        data: CHPBypassEnergy,
        startTime,
        type: "area",
        componentList: CPType,
      });
      seriesWrappers.push(bypassWrapper);
    }

    // Sort and add series wrappers to series array
    seriesWrappers
      .sort((a, b) => a.sortValue - b.sortValue)
      .forEach((value) => {
        series.push(value.series);
      });

    // Helper function to create demand series for components
    const createDemandSeries = (component: any, i: any) => {
      CPType.push(component.type);
      return createSeries({
        i,
        component,
        sortValue: 0,
        data: getEnergyRequiredSeries(component, demandParameter),
        startTime,
        type: "line",
        componentList: CPType,
      });
    };

    // Add demand for each Demand component
    const demands = results.components
      .filter((x) => DEMAND_COMPONENTS.includes(x.type))
      .filter(
        (x) =>
          !(x.type === ComponentType.COOLING_DEMAND && validTypes !== COOLING_PRODUCING_COMPONENTS)
      )
      .filter(
        (x) =>
          !(HEAT_DEMAND_COMPONENTS.includes(x.type) && validTypes !== HEAT_PRODUCING_COMPONENTS)
      );

    demands.forEach((component, i) => {
      const demandSeries = createDemandSeries(component, i);
      series.push(demandSeries.series);
    });

    // Calculate and add total demand series
    const totalDemand = demands.reduce((total: any, component) => {
      CPType.push(component.type);
      const demand = getEnergyRequiredSeries(component, demandParameter);
      if (total.length === 0) {
        return [...demand];
      } else {
        return total.map((value: any, index: any) => value + demand[index]);
      }
    }, []);

    if (totalDemand.length) {
      CPType.push(ComponentType.TOTAL_DEMAND);
      const totalDemandSeries = createSeries({
        component: { name: "Total Demand", type: ComponentType.TOTAL_DEMAND },
        sortValue: 0,
        data: totalDemand,
        startTime,
        type: "line",
        componentList: CPType,
        isVisible: demands.length > 1,
        i: 0,
      });
      series.push(totalDemandSeries.series);
    }

    return series;
  }, [results, validTypes, demandParameter, startTime, demandType, getMaxProduction]);

  // Update visibleSeries when totalSeries changes
  useEffect(() => {
    if (totalSeries.length === 0) return;

    if (Object.keys(visibleSeries).length === 0) {
      const newVisibleSeries: { [key: string]: boolean } = {};
      totalSeries.forEach((serie) => {
        newVisibleSeries[serie.id] = serie.visible;
      });
      setVisibleSeries((prevVisibleSeries) => ({
        ...prevVisibleSeries,
        ...newVisibleSeries,
      }));
    }
  }, [totalSeries, visibleSeries]);

  const series = useMemo(() => {
    if (totalSeries.length === 0) return [];
    let updateSeries = totalSeries;

    if (averaged) {
      updateSeries = updateSeries.map((serie) => ({ ...serie, pointInterval: 24 * 3600000 }));
    }

    return updateSeries.map((serie) => {
      if (Object.hasOwn(visibleSeries, serie.id)) {
        // Highcharts options
        return {
          ...serie,
          visible: visibleSeries[serie.id],
          // More than 2 weeks of hourly data will cause the chart to be slow
          boostThreshold: 14 * 24,
          dataGrouping: {
            enabled: true,
            forced: true,
            units: [["hour", [1, 12, 24]]],
          },
        };
      }
      return serie;
    });
  }, [visibleSeries, totalSeries, averaged]);

  return (
    <HighchartsBase
      loading={loading}
      yUnit={unit}
      series={series}
      chart={{
        type: "area",
        height: HEIGHT,
      }}
      plotOptions={{
        area: {
          stacking: "normal",
        },
        series: {
          marker: false,
          color: "",
          events: {
            legendItemClick: createSeriesToggleHandler(setVisibleSeries),
          },
        },
      }}
      xAxis={{
        title: {
          text: "Time",
        },
        type: "datetime",
        tickInterval: averaged ? INTERVAL_DAILY : INTERVAL_HOURLY,
        labelFormatType: averaged ? "date" : "dateOrTime",
      }}
      yAxisOptions={{
        title: {
          text: "Power",
        },
        resize: {
          enabled: true,
        },
      }}
      tooltip={{
        pointFormatter() {
          return `<span style="color:${this.color}">\u25CF</span> ${
            this.series.name
          }: <b>${formatNumberForLocale(this.y)} ${unit}</b><br/>`;
        },
      }}
      legend={{ enabled: true, layout: "horizontal", align: "center", verticalAlign: "bottom" }}
      disableBoost
    />
  );
}

export default observer(EnergyProductionGraph);
