import React from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

type ChartModalProps = {
  data: number[];
  chartTitle?: string;
};

const ChartModal = ({ data, chartTitle }: ChartModalProps) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const options = {
    title: {
      text: `Hourly ${chartTitle}s`,
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "Date",
      },
    },
    yAxis: {
      title: {
        text: chartTitle,
      },
    },
    tooltip: {
      headerFormat: "<b>{point.x:%A, %b %e, %H:%M}</b><br/>",
      pointFormat: `${chartTitle}: {point.y:.2f}`,
    },
    // Not sure why but on the modal this will start from 31 Dec 2024 at 22:00 and end 31 Dec 2025 21:00
    // Under the assumption that this will be for te year 2025, this should be dynamic somehow based on the input file
    series: [
      {
        name: chartTitle,
        data: data.map((price, index) => {
          const date = new Date(2025, 0, 1);

          const hours = Math.floor(index);
          const days = Math.floor(hours / 24);
          const remainingHours = hours % 24;

          date.setDate(1 + days);
          date.setHours(remainingHours);
          date.setMinutes(0);
          date.setSeconds(0);

          return [date.getTime(), price];
        }),
        type: "line",
      },
    ],
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        data-testid="view-file"
        sx={{
          mt: 2,
          borderRadius: "20px",
        }}
      >
        View file
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent>
          <div style={{ height: "70vh" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              containerProps={{ style: { height: "100%" } }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChartModal;
