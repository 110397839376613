import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { DateTime } from "luxon";

import { toCamelCase } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { getComponentColor } from "@pages/Optimization/Opt.utils";
import { BaseCard } from "@shared/ui/analytics/cards";
import { HighchartsBase } from "@shared/ui/analytics/charts";

import { StorageComponentTypes } from "../../../Opt.const";
import { defaultPointFormatter } from "./chart_utils";

const HEIGHT = 720;

const validTypes = StorageComponentTypes;
const unit = "kWh";

function createSeries(component, sortValue, data, startTime) {
  const series = {
    name: `${component.name} ${sortValue}`,
    yAxis: 0,
    pointInterval: 3600000,
    pointStart: startTime,
    data,
    color: getComponentColor(component),
    tooltip: {
      pointFormatter: defaultPointFormatter,
      valueSuffix: unit,
    },
    threshold: 0,
    id: toCamelCase(component.name),
  };

  return {
    series,
    sortValue,
  };
}
function StoredEnergyGraph({ results }) {
  const { t } = useTranslation(["production"]);

  const [visibleSeries, setVisibleSeries] = useState({});
  const [series, setSeries] = useState([]);

  const startTime = DateTime.fromISO(results.input_parameters.start_time).toMillis();

  useEffect(() => {
    const newVisibleSeries = {};
    const newSeries = [];

    results.components.forEach((component) => {
      if (validTypes.includes(component.type)) {
        const data = component.variables.stored.map((x) => x.current_value);
        const sortValue = "";

        newVisibleSeries[toCamelCase(component.name)] = true;

        const seriesWrapper = createSeries(component, sortValue, data, startTime);
        newSeries.push(seriesWrapper.series);
      }
    });

    setVisibleSeries((prevVisibleSeries) => ({
      ...prevVisibleSeries,
      ...newVisibleSeries,
    }));
    setSeries(newSeries);
  }, [results]);

  const serieList = useMemo(() => {
    if (series.length === 0) return [];
    return series.map((serie) => {
      if (Object.hasOwn(visibleSeries, serie.id)) {
        return { ...serie, visible: visibleSeries[serie.id] };
      }
      return serie;
    });
  }, [visibleSeries, series]);

  return (
    <BaseCard title={t("text_stored_energy")} testId="opt-chart-stored-energy">
      <HighchartsBase
        series={serieList}
        yUnit={unit}
        chart={{
          type: "line",
          height: HEIGHT,
        }}
        plotOptions={{
          series: {
            marker: false,
            color: "",
            events: {
              legendItemClick: createSeriesToggleHandler(setVisibleSeries),
            },
          },
        }}
        xAxis={{
          title: {
            text: "Time",
          },
          type: "datetime",
        }}
        yAxisOptions={{
          title: {
            text: "Energy",
          },
          resize: {
            enabled: true,
          },
        }}
        legend={{ enabled: true, layout: "horizontal", align: "center", verticalAlign: "bottom" }}
        disableBoost
      />
    </BaseCard>
  );
}

export default observer(StoredEnergyGraph);
