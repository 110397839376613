import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";

import alternativeCostStore from "@stores/AlternativeCost/AlternativeCost.store";

import UtfFileReader from "../../../../../../libs/components/src/ui/inputs/UtfFileReader/UtfFileReader";

const WeatherCard = ({
  data,
  onChange,
}: {
  data: {
    values: number[];
    name: string;
  };
  onChange: (values: number[], name: string) => void;
}) => {
  const collectionId = alternativeCostStore.get.selectedCollection()?.id;
  const configId = alternativeCostStore.get.selectedConfiguration()?.id;

  const storageKey = `alt-cost/meta-data/${collectionId}/${configId}/weather`;

  const [fileName, setFileName] = useState<string>("");
  const [fileData, setFileData] = useState<number[]>([]);

  const handleDataRead = useCallback(
    (values: number[], name: string) => {
      setFileName(name);
      setFileData(values);

      const dataToStore = {
        name,
        values,
      };

      localStorage.setItem(storageKey, JSON.stringify(dataToStore));

      onChange(values, name);
    },
    [storageKey]
  );

  // Retrieve and set stored data from localStorage when the component mounts or storageKey changes
  useEffect(() => {
    const storedData = localStorage.getItem(storageKey);
    if (storedData && storedData !== "undefined") {
      try {
        const { values, name } = JSON.parse(storedData);
        setFileName(name);
        setFileData(values);
      } catch (err) {
        console.error("Failed to parse stored data:", err);
      }
    }
  }, [storageKey]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "75%" }}>
      <UtfFileReader
        key={fileName}
        onDataRead={handleDataRead}
        initialFileName={fileName}
        initialData={fileData}
        accept=".csv"
        chartTitle="Outdoor Temperature"
      />
    </Box>
  );
};

export default WeatherCard;
