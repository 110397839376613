import { darken } from "@mui/system";

import { isValue } from "@core/utils";
import { getComponentColor } from "@pages/Optimization/Opt.utils";
import { formatTooltipValue } from "@shared/ui/analytics/charts/utils";

export function filter_y1_data(series) {
  const res = series
    .filter((s) => s.yAxis === 1)
    .map((d) => d.data.filter((i) => typeof i === "number"))[0];
  return isValue(res) && res.length > 0 ? res[0] : [];
}

/**
 * if graph has multiple type of the same components. make those components has diff color
 * @param {OptComponent} component type of each component
 * @param {Array} list list of component
 * @param {Number} i index of component in list
 *
 * @returns {string} hex color code
 */
export function darkenGraphColor({ component, list, i = 0 }) {
  const isDuplicate = new Set(list).size !== list.length;
  const color =
    getComponentColor(component) || `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  return isDuplicate ? darken(color, parseFloat(`0.${i}`)) : color;
}

export function defaultPointFormatter() {
  const { color, y } = this;
  const { name, tooltip } = this.series.userOptions;
  return `<span style="color:${color}">●</span> ${name}:<b> ${formatTooltipValue(
    y,
    tooltip.valueSuffix
  )}</b><br/>`;
}
