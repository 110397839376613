import { logger as baseLogger } from "@core/logger";
import { ExtendedTariffPlan, Network, NetworkTariffPlan, TariffPlan } from "@core/models";

import axiosClient from "../apiClient";
import { MdslApi } from "./mdsl.api";

const logger = baseLogger.getSubLogger({ name: "admin.api" });

// Only endpoints that start with /admin are implemented here
export class AdminApi extends MdslApi {
  // createNetwork
  async createNetwork(
    uid: string,
    networkName: string,
    displayName: string,
    policyUid: string | undefined
  ): Promise<Network> {
    const authToken = await this.getAuthToken();
    // if 409, network already exists with the same name
    if (!networkName) {
      throw new Error("Network name cannot be empty");
    }
    if (!policyUid) {
      throw new Error("Policy UID cannot be empty");
    }

    logger.debug("Creating network", { uid, networkName, displayName, policyUid });

    return axiosClient
      .post(
        `/admin/policies/${policyUid}/networks`,
        {
          uid,
          name: networkName,
          displayName: displayName,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => res.data.data);
  }

  // /v4/admin/tariff-plans
  async getTariffPlans(): Promise<TariffPlan[]> {
    const authToken = await this.getAuthToken();
    return axiosClient
      .get("/admin/tariff-plans", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data.collection);
  }

  // /v4/admin/tariff-plans/{tariff_plan_uid}
  async getTariffPlanById(tariffPlanUid: string): Promise<ExtendedTariffPlan> {
    const authToken = await this.getAuthToken();
    return axiosClient
      .get(`/admin/tariff-plans/${tariffPlanUid}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data);
  }

  // /v4/admin/tariff-plans/networks/{networkUID}
  async getNetworkTariffPlans(networkUid: string | undefined): Promise<NetworkTariffPlan[]> {
    if (!networkUid) {
      throw new Error("Network UID cannot be empty");
    }
    const authToken = await this.getAuthToken();
    return axiosClient
      .get(`/admin/tariff-plans/networks/${networkUid}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data.collection);
  }

  // /v4/admin/tariff-plans/{tariffPlanUID}/networks/{networkUID}
  async addTariffPlanToNetwork(arg0: {
    networkUid: string | null | undefined;
    tariffPlanUid: string | null | undefined;
    contractType: string | null;
    validFrom: string | null | undefined;
    validTo: string | null | undefined;
  }): Promise<any> {
    const { networkUid, tariffPlanUid, validFrom, validTo, contractType } = arg0;
    if (!networkUid) {
      throw new Error("Network UID cannot be empty");
    }
    if (!tariffPlanUid) {
      throw new Error("Tariff Plan UID cannot be empty");
    }
    // Need at least validFrom
    if (!validFrom) {
      throw new Error("Valid From cannot be empty");
    }
    const authToken = await this.getAuthToken();
    return axiosClient
      .put(
        `/admin/tariff-plans/${tariffPlanUid}/networks/${networkUid}`,
        {
          valid_from: validFrom,
          valid_to: validTo,
          contract_type: contractType,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => res.data.data);
  }
}

export default new AdminApi();
