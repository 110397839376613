import React, { useCallback, useEffect, useState } from "react";
import { TextFieldElement, useForm } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { Box, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";

import { AltCostCollection } from "@customTypes/production/sales";

import alternativeCostStore from "@stores/AlternativeCost/AlternativeCost.store";
import { NetworkStore } from "@stores/networks";
import { useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared";

import UtfFileReader from "../../../../../../libs/components/src/ui/inputs/UtfFileReader/UtfFileReader";

export type AltCostTradePriceCardProps = {
  data: {
    values: number[];
    name: string;
    value: number;
  };
  onChange: (values: number[], name: string, value: number) => void;
};

const ElectricityTradePriceCard = ({ data, onChange }: AltCostTradePriceCardProps) => {
  const { t } = useTranslation(["sales"]);
  const theme = useTheme();

  const { networks }: { networks: NetworkStore } = useInject("networks");
  const currency = toJS(networks.currency);

  // State for local data management
  const [priceValues, setPriceValues] = useState<number[]>(data.values || []);
  const [fileName, setFileName] = useState<string>(data.name || "");
  const [addonValue, setAddonValue] = useState<number>(data.value || 0);

  const collectionId = alternativeCostStore.get.selectedCollection()?.id;
  const configId = alternativeCostStore.get.selectedConfiguration()?.id;
  const storageKey = `alt-cost/meta-data/${collectionId}/${configId}/electricity`;

  const { control: formControl } = useForm<AltCostCollection>({
    defaultValues: {
      electricityPrice: priceValues,
      electricityPriceDescription: fileName,
      electricityPriceAddon: addonValue,
    },
  });

  // Load data from localStorage on mount
  useEffect(() => {
    const storedData = localStorage.getItem(storageKey);
    if (storedData && storedData !== "undefined") {
      try {
        const parsedData = JSON.parse(storedData);
        setPriceValues(parsedData.values || []);
        setFileName(parsedData.name || "");
        setAddonValue(parsedData.value || 0);

        onChange(parsedData.values || [], parsedData.name || "", parsedData.value || 0);
      } catch (err) {
        console.error("Failed to parse stored data:", err);
      }
    }
  }, [storageKey]);

  // Sync with props when they change externally
  useEffect(() => {
    // Only update state if props are different from current state
    // to avoid unnecessary re-renders
    if (
      JSON.stringify(data.values) !== JSON.stringify(priceValues) ||
      data.name !== fileName ||
      data.value !== addonValue
    ) {
      setPriceValues(data.values || []);
      setFileName(data.name || "");
      setAddonValue(data.value || 0);
    }
  }, [data]);

  // Handler for file data reading
  const handleDataRead = useCallback(
    (values: number[], name: string) => {
      // Only update if values are actually different
      if (JSON.stringify(values) !== JSON.stringify(priceValues) || name !== fileName) {
        setPriceValues(values);
        setFileName(name);

        const dataToStore = {
          name,
          values,
          value: addonValue,
        };

        localStorage.setItem(storageKey, JSON.stringify(dataToStore));
        onChange(values, name, addonValue);
      }
    },
    [storageKey, addonValue, fileName, priceValues, onChange]
  );

  const handleAddonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(event.target.value);
      if (!isNaN(newValue) && newValue !== addonValue) {
        setAddonValue(newValue);

        const dataToStore = {
          name: fileName,
          values: priceValues,
          value: newValue,
        };

        localStorage.setItem(storageKey, JSON.stringify(dataToStore));
        onChange(priceValues, fileName, newValue);
      }
    },
    [fileName, priceValues, storageKey, addonValue, onChange]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "75%" }}>
      <UtfFileReader
        key={fileName}
        onDataRead={handleDataRead}
        initialFileName={fileName}
        initialData={priceValues}
        accept=".csv"
      />
      <Box component="form" sx={{ width: "100%" }} noValidate autoComplete="off">
        <Stack spacing={1} display="flex" p={2} alignItems="center" sx={{ width: "100%" }}>
          <Stack
            direction="row"
            width="100%"
            justifyContent="start"
            alignItems="center"
            spacing={2}
          >
            <TextFieldElement
              name="electricityPriceAddon"
              label={t("text_electricity_price_add_on")}
              control={formControl}
              value={addonValue}
              size="small"
              fullWidth
              type="number"
              variant="standard"
              onChange={handleAddonChange}
              InputProps={{
                endAdornment: <Typography>{currency}</Typography>,
              }}
            />
            <FontAwesomeSvgIcon
              icon={faQuestionCircle}
              sx={{ color: theme.palette.primary.main, fontSize: 14 }}
              data-testid="help-icon"
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default ElectricityTradePriceCard;
