import {
  OptComponent,
  OptComponentProperty,
  OptComponentType,
} from "@customTypes/production/optimization";
import { AlternativeCostConfigForm } from "@customTypes/production/sales";

import { getRandomHexColor } from "@core";

// We only support a few component types
export const SUPPORTED_ALT_COST_COMPONENTS = ["ALT_COST_BUILDING", "ALT_COST_HEAT_PRODUCER"];

export const fnCalculateComponents = (
  formValues: AlternativeCostConfigForm,
  selectedConfigComponents: OptComponent[],
  componentTypes: OptComponentType[]
) => {
  if (!formValues || !selectedConfigComponents || !componentTypes) {
    console.error("Error calculating components, no config form");
    return [];
  }
  // We also have  a mapping between FE values and Component paramaters
  const valuesToComponentParams = {
    ALT_COST_BUILDING: {
      heated_area: formValues.heatedArea,
      energy_signature: [
        formValues.energySignatureA || 0,
        formValues.energySignatureB || 0,
        formValues.energySignatureT0 || 0,
      ].join(","),
      interest_rate: formValues.interestRate,
      radiator_design_temperature: formValues.radiatorDesignTemp,
    },
    ALT_COST_HEAT_PRODUCER: {
      borehole_depreciation_time: formValues.boreholeDepreciation,
      heat_pump_depreciation_time: formValues.heatPumpDepreciation,
      heat_pump_type: formValues.heatPumpType,
      // "installed_heat_pump_capacity": get.calculatedValues().installedHeatPumpCapacity,
      // "installed_peak_boiler_capacity": get.calculatedValues().installedPeakBoilerCapacity,
      investment_cost_kwh_heat_pump: formValues.investmentCostHeatPump,
      investment_cost_kwh_borehole: formValues.investmentCostBorehole,
      investment_cost_kwh_boiler: formValues.investmentCostPeakBoiler,
      o_and_m_cost: formValues.omCost,
      peak_boiler_depreciation_time: formValues.peakBoilerDepreciation,
      // peak_boiler_type: configForm.boilerType,
      power_coverage_factor: formValues.powerCoverage,
    },
  };

  // Filter out the supported component types
  const viableComponentTypes = componentTypes.filter((type) =>
    SUPPORTED_ALT_COST_COMPONENTS.includes(type.type)
  );
  // Make a new array with the updated values
  const newComponents: OptComponent[] = [];
  // Make an array of all available properties in componentTypes
  const allProperties = viableComponentTypes.reduce((acc, type) => {
    return acc.concat(type.properties);
  }, [] as OptComponentProperty[]);

  viableComponentTypes.forEach((componentType) => {
    const existingConfigComponent = selectedConfigComponents.find(
      (c) => c.type === componentType.type
    );
    // @ts-expect-error - We know this is a valid type
    const requiredParams = valuesToComponentParams[componentType.type] || {};
    // Then for each param, key value
    // @ts-expect-error - We should fix the type on OptComponentProperty
    const newParams: OptComponentProperty[] = Object.entries(requiredParams).map(([key, value]) => {
      return {
        id: allProperties.find((p) => p.technicalName === key)?.id || 0,
        value: value,
        technical_name: key,
      };
    });
    // Add the new params to the component
    newComponents.push({
      id: existingConfigComponent?.componentId || 0,
      name: existingConfigComponent?.label || componentType.label,
      color: existingConfigComponent?.color || getRandomHexColor(),
      // From the component type
      type_id: componentType.id,
      label: componentType.label,
      type: componentType.type,
      properties: newParams,
      sort_order: 0,
    });
  });

  return newComponents;
};
