import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { DateTime } from "luxon";

import { toCamelCase } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { getComponentColor } from "@pages/Optimization/Opt.utils";
import { BaseCard } from "@shared/ui/analytics/cards";
import { HighchartsBase } from "@shared/ui/analytics/charts";

import { ComponentType, StorageComponentTypes } from "../../../Opt.const";
import { defaultPointFormatter } from "./chart_utils";

const HEIGHT = 720;

const storedEnergyTypes = StorageComponentTypes;
const controlTypes = [ComponentType.HP];
const unit = "kW";

function createSeries(component, sortValue, data, startTime) {
  const series = {
    name: component.name,
    yAxis: 0,
    color: getComponentColor(component),
    pointInterval: 3600000,
    pointStart: startTime,
    data,
    tooltip: {
      pointFormatter: defaultPointFormatter,
      valueSuffix: unit,
    },
    threshold: 0,
    id: toCamelCase(component.name),
  };

  return {
    series,
    sortValue,
  };
}

function ChargeDischargeGraph({ results }) {
  const { t } = useTranslation(["production"]);

  const startTime = DateTime.fromISO(results.input_parameters.start_time).toMillis();

  const [series, setSeries] = useState([]);
  const [visibleSeries, setVisibleSeries] = useState({});

  useEffect(() => {
    const seriesArray = [];
    const visibleSeries = {};
    results.components.forEach((comp) => {
      let seriesWrapper = null;
      if (controlTypes.includes(comp.type) || storedEnergyTypes.includes(comp.type)) {
        const inArr = comp.variables.in.map((v) => v.current_value);
        const outArr = comp.variables.out.map((v) => v.current_value);
        const inOutArr = inArr.map((v, i) => v - outArr[i]);
        seriesWrapper = createSeries(comp, "", inOutArr, startTime);
        seriesArray.push(seriesWrapper.series);
        visibleSeries[toCamelCase(comp.name)] = true;
      }
    });
    setSeries(seriesArray);
    setVisibleSeries((prevVisibleSeries) => ({
      ...prevVisibleSeries,
      ...visibleSeries,
    }));
  }, [results, startTime]);

  const serieList = useMemo(() => {
    if (series.length === 0) return [];
    return series.map((serie) => {
      if (Object.hasOwn(visibleSeries, serie.id)) {
        return { ...serie, visible: visibleSeries[serie.id] };
      }
      return serie;
    });
  }, [series, visibleSeries]);

  return (
    <BaseCard title={t("text_charge_discharge")} testId="opt-chart-charge-discharge">
      <HighchartsBase
        series={serieList}
        chart={{
          type: "line",
          height: HEIGHT,
          alignTicks: false,
        }}
        yUnit={unit}
        plotOptions={{
          series: {
            marker: false,
            events: {
              legendItemClick: createSeriesToggleHandler(setVisibleSeries),
            },
          },
        }}
        xAxis={{
          title: {
            text: "Time",
          },
          type: "datetime",
        }}
        yAxis={{
          resize: {
            enabled: true,
          },
        }}
        yAxisOptions={{
          title: {
            text: "Power",
          },
          resize: {
            enabled: true,
          },
          plotLines: [
            {
              width: 3,
              value: 0,
              zIndex: 5,
            },
          ],
        }}
        legend={{ enabled: true, layout: "horizontal", align: "center", verticalAlign: "bottom" }}
        disableBoost
      />
    </BaseCard>
  );
}

export default observer(ChargeDischargeGraph);
