import { createRoot } from "react-dom/client";
import { configure } from "mobx";
import { Provider } from "mobx-react";
import { HistoryAdapter, RouterContext } from "mobx-state-router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { APIProvider } from "@vis.gl/react-google-maps";
import { ClickToComponent } from "click-to-react-component";
import { PostHogProvider } from "posthog-js/react";

import {
  GMAPS_KEY,
  PLAYWRIGHT_RUN,
  POSTHOG_API_KEY,
  POSTHOG_HOST,
  SENTRY_DSN,
  SENTRY_ENABLED,
} from "@conf/config";

import { App } from "./app";
import { history } from "./history";
import { rootStore } from "./stores/root_store";
import { MuiThemeProvider } from "./ThemeProvider";

import "@core/i18n";
import "@core/fonts";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// # MOBX
const isStrictMobx = import.meta.env.REACT_APP_MOBX_STRICT_MODE === "true";
configure({
  enforceActions: isStrictMobx ? "always" : "never",
  computedRequiresReaction: isStrictMobx,
  reactionRequiresObservable: isStrictMobx,
  observableRequiresReaction: isStrictMobx,
  disableErrorBoundaries: isStrictMobx,
});

const { MODE } = import.meta.env;

// # SENTRY
if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: MODE,
    // Capture transactions for performance monitoring.
    tracesSampleRate: MODE === "qa" ? 0.1 : 1.0,
    // Capture use interactions. Disabled on QA (PR-Previews)
    replaysSessionSampleRate: MODE === "qa" ? 0 : 1.0,
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    profilesSampleRate: MODE === "qa" ? 0 : 0.5,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // Origins
    tracePropagationTargets: [import.meta.env.REACT_APP_MDSL_V4_URL],
    // Enable integrations
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        // mask only the elements marked with following selectors
        mask: ["[data-maskdata]", "[data-sentry-mask]"],
      }),
    ],
  });
}

// # POSTHOG
const postHogOptions = {
  api_host: POSTHOG_HOST,
};

// Router History
const hAdaptor = new HistoryAdapter(rootStore.routerStore, history);
hAdaptor.observeRouterStateChanges();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Timeout after 30 seconds
      staleTime: 1000 * 30,
    },
  },
});

// # APP RENDERER
const domNode = document.getElementById("root");
if (domNode) {
  const root = createRoot(domNode);
  root.render(
    <PostHogProvider apiKey={POSTHOG_API_KEY} options={postHogOptions}>
      <RouterContext.Provider value={rootStore.routerStore}>
        <Provider
          rootStore={rootStore}
          dash={rootStore.dash}
          session={rootStore.session}
          frcst={rootStore.frcst}
          production={rootStore.production}
          networks={rootStore.networks}
          sub={rootStore.sub}
          ui={rootStore.ui}
          rta={rootStore.rta}
          distdash={rootStore.distdash}
          auth={rootStore.auth}
          pricing={rootStore.pricing}
          newapi={rootStore.newapi}
          faultDetectionApi={rootStore.faultDetectionApi}
          preport={rootStore.preport}
          sales={rootStore.sales}
          meter={rootStore.meter}
          opt_store={rootStore.opt_store}
          settingsStore={rootStore.settingsStore}
          preferences={rootStore.preferences}
          notifications={rootStore.notifications}
          language={rootStore.language}
          interaction={rootStore.interaction}
        >
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <MuiThemeProvider>
              <QueryClientProvider client={queryClient}>
                <APIProvider apiKey={GMAPS_KEY}>
                  <App />
                </APIProvider>
                <ClickToComponent editor={import.meta.env.REACT_APP_CLICK_TO_COMPONENT_EDITOR} />
                {!PLAYWRIGHT_RUN && <ReactQueryDevtools initialIsOpen={false} />}
              </QueryClientProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </RouterContext.Provider>
    </PostHogProvider>
  );
}
