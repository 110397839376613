import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import { formatNumberForUnitMainLabel } from "@core/utils";

import { LargeNumber } from "../../LargeNumber";
import BaseCard from "../BaseCard/BaseCard";

export type CompareCardValue = {
  title: string;
  value: number | null;
  formattedUnit?: number | null;
  formattedValue?: number | null;
  formattedExp?: number | null;
};
type CompareCardProps = {
  title?: string;
  values: CompareCardValue[];
  unit?: string;
  loading?: boolean;
  error?: boolean;
  errorMessage?: string;
  testId?: string;
};
/**
 * - This card is built on top of the BaseCard component.
 * - The card is used to compare up to 3 values.
 * - The card displays a number with a unit as the titleAddon
 * - The value has a title that is displayed below the value.
 * - The input values is an array of objects with the following structure:
 *  {
 *    title: "Title",
 *    value: 123,
 *  }
 * - The unit is shared between all the values.
 */
export default function CompareCard({
  title = "",
  values,
  unit = "",
  loading = false,
  error = false,
  errorMessage = "Unable to load data",
  testId = "compare-card",
}: CompareCardProps) {
  const [formattedValues, setFormattedValues] = useState<CompareCardValue[]>([]);
  const [internalError, setError] = useState(error);
  const [internalErrorMessage, setErrorMessage] = useState(errorMessage);
  const colorArray = ["graph.blue", "graph.pink", "graph.teal"];

  // If it is a number and the unit is not empty, format the value and unit.
  useEffect(() => {
    if (!loading && typeof values[0]?.value === "number" && unit) {
      const result = values.map((value) => {
        const [formattedValue, formattedExp, formattedPrefix, formattedUnit] =
          formatNumberForUnitMainLabel(value.value, unit);
        return {
          ...value,
          formattedValue,
          formattedExp,
          formattedPrefix,
          formattedUnit,
        };
      });
      setFormattedValues(result as CompareCardValue[]);
    }
  }, [loading, values, unit]);

  // if the units dont match, set error to true
  useEffect(() => {
    // TODO: This can be handled better if we need to handle different units
    if (formattedValues.length > 1) {
      const resultUnits = formattedValues[0]?.formattedUnit;
      const result = formattedValues.filter((value) => value.formattedUnit !== resultUnits);
      if (result.length > 0) {
        setError(true);
        setErrorMessage("Units do not match");
      }
    }
  }, [formattedValues]);

  return (
    <BaseCard
      title={title}
      loading={loading}
      error={internalError}
      errorMessage={internalErrorMessage}
      testId={testId}
    >
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent="space-between"
        alignItems="stretch"
        width="100%"
        height="100%"
        p={2}
      >
        {/* Content */}
        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
          justifyContent="center"
          alignContent="center"
          py={2}
        >
          {formattedValues.map((value, index) => (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              px={2}
              key={value.title}
            >
              <Box display="flex" flexDirection="row" justifyContent="center">
                <LargeNumber
                  value={value.formattedValue!}
                  exp={value.formattedExp!}
                  color={colorArray[index]}
                  unit={unit}
                  tooltip={`${new Intl.NumberFormat("se-SE").format(value.value!)} ${unit}`}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                color={colorArray[index]}
              >
                <Typography variant="subtitle2">{value.title}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </BaseCard>
  );
}
