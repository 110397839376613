/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { queryOptions } from "@tanstack/react-query";

import {
  OptJobStartParams,
  OptJobStartParamsAltCost,
  OptJobStartParamsOptimization,
  OptJobStatus,
} from "@customTypes/production/optimization";

import OptimizationApi from "./optimization.api";

export const getNetworkSettings = (api: OptimizationApi, networkId: string | undefined | null) =>
  queryOptions({
    queryKey: ["optimization", networkId, "networkSettings"],
    queryFn: () => api.getNetworkSettings(networkId!),
    enabled: !!networkId,
  });

export const getComponentTypes = (api: OptimizationApi, networkId: string | undefined | null) =>
  queryOptions({
    queryKey: ["optimization", networkId, "componentTypes"],
    queryFn: () => api.getComponentTypes(networkId!),
    enabled: !!networkId,
  });

export const startSimulationOptions = (
  api: OptimizationApi,
  networkId: string | undefined | null,
  configId: number | undefined | null,
  startSimulation: boolean,
  body: OptJobStartParams | OptJobStartParamsAltCost | OptJobStartParamsOptimization
) =>
  queryOptions({
    queryKey: ["optimization", networkId, "simulation", configId, "run"],
    queryFn: () => api.startSimulation(body),
    enabled: startSimulation,
  });

export const getSimulationStatusOptions = (
  api: OptimizationApi,
  networkId: string | undefined | null,
  jobId: number | undefined | null
) =>
  queryOptions({
    queryKey: ["optimization", networkId, "simulation", jobId, "status"],
    queryFn: () => api.getSimulationStatus(jobId!),
    enabled: !!jobId,
    refetchInterval(query) {
      // Internal ReactQuery error
      if (query.state.status === "error") {
        return false;
      }
      if (query.state.data) {
        const status = query.state.data;
        const states = ["in_progress", "init"];
        return states.includes(status) ? 5000 : false;
      }

      return 5000;
    },
    retry: false,
  });

export const getSimulationResultsOptions = (
  api: OptimizationApi,
  networkId: string | undefined | null,
  jobId: number | undefined | null,
  jobStatus: OptJobStatus | undefined | null
) =>
  queryOptions({
    queryKey: ["optimization", networkId, "simulation", jobId, "results"],
    queryFn: () => api.getSimulationResults(jobId!),
    enabled: !!networkId && !!jobId && jobStatus === "done",
    // Dont refetch
    staleTime: Infinity,
  });
