/* eslint-disable i18next/no-literal-string */
import { useState } from "react";
import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { AppBar, Link, Tab, Toolbar, Typography, type Theme } from "@mui/material";
import { Box, useTheme } from "@mui/system";

import { PageTitle } from "../../components/app/PageTitle";

const PrivacyPolicy = () => {
  const [language, setLanguage] = useState("en");
  const theme = useTheme<Theme>();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setLanguage(newValue);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        maxHeight: "100vh",
      }}
    >
      <AppBar
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: "primary.dark",
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar sx={{ "&": { padding: 0 } }}>
          {/* page title */}
          <PageTitle title={"Privacy Policy"} pageIcon={null} onClick={() => null} />
        </Toolbar>
      </AppBar>
      <Box sx={{ height: "64px" }} />
      <TabContext value={language}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
            <Tab label="English" value="en" />
            <Tab label="Swedish" value="se" />
          </TabList>
        </Box>
        <TabPanel value="en">
          <Box p={4}>
            <Typography variant="h2" gutterBottom>
              Privacy Policy for Utilifeed
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Last Updated: 3 June 2024
            </Typography>
            <Typography variant="body1" gutterBottom>
              At Utilifeed, we are committed to ensuring the privacy and protection of your personal
              data. This Privacy Policy outlines our practices regarding the collection, use, and
              disclosure of your information. Please read this policy carefully to understand how we
              handle your data.
            </Typography>
            <Typography variant="h3" gutterBottom>
              Cookies and Other Tracking Technologies:
            </Typography>
            <Typography variant="body1" gutterBottom>
              Utilifeed and our third-party partners, use cookies and local storage to provide
              functionality. For more information, please see our Cookies and Tracking Notice, which
              includes information on how to control or opt out of these cookies and tracking
              technologies.
            </Typography>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h3" gutterBottom>
                1. Identity and Contact Details
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Organization:</strong> Utilifeed
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Representative:</strong> Samuel Jansson
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Data Protection Officer:</strong> Johan Kensby
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Contact Email:</strong>{" "}
                <Link href="mailto:johan.kensby@utilifeed.com">johan.kensby@utilifeed.com</Link>
              </Typography>
              <Typography variant="h3" gutterBottom>
                2. Purpose and Legal Basis
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 6(1)(b)</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                We process your personal data for the following purposes and legal bases:
              </Typography>
              <Typography variant="body1" component="ul" gutterBottom>
                <li>
                  <strong>Purpose:</strong> To provide access to our digital SaaS platform for
                  District Heating companies.
                </li>
                <li>
                  <strong>Legal Basis:</strong> Performance of a contract.
                </li>
              </Typography>
              <Typography variant="h3" gutterBottom>
                3. Legitimate Interests
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 6(1)(f)</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed may process your data based on legitimate interests. These include:
              </Typography>
              <Typography variant="body1" component="ul" gutterBottom>
                <li>Improving and enhancing our services and platform.</li>
                <li>Finding and tracking crash reports to create a bug free system.</li>
                <li>Ensuring the security of our platform.</li>
              </Typography>
              <Typography variant="h3" gutterBottom>
                4. Recipients of Data
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 13(1)(e), 44, 46</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                We may share your data with the following recipients who are our sub-processors:
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Third Parties:</strong> Auth0, PostHog
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Purpose:</strong> Authentication, analytics for improvement.
              </Typography>
              <Typography variant="body1" gutterBottom>
                See our Cookies and Tracking Notice (below) for more information
              </Typography>
              <Typography variant="h3" gutterBottom>
                5. Data Transfers
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 44, 46, 49</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed prioritizes the security and confidentiality of your personal data. Any
                transfer of your data to third countries will only occur when absolutely necessary
                for the specified purposes outlined in this policy. We ensure that such transfers
                adhere to stringent data protection standards, and appropriate safeguards are
                meticulously implemented to guarantee the integrity and security of your
                information.
              </Typography>
              <Typography variant="h3" gutterBottom>
                6. Retention Period
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 5(1)(e)</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Your data&lsquo;s retention is handled with precision and purpose at Utilifeed. We
                retain your personal data only for the duration required to fulfill the specified
                purposes detailed in this policy. The retention period is determined based on legal
                obligations, contractual necessity, or the necessity of the data for the initiation,
                exercise, or defense of legal claims. Utilifeed prioritizes secure data storage
                practices. Any data stored by Utilifeed is handled with extreme care and is
                encrypted during transit.
              </Typography>
              <Typography variant="h3" gutterBottom>
                7. Data Subject Rights
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 15, 16, 17</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                You have the right to access, rectify, and erase your personal data. To exercise
                these rights, please contact us at{" "}
                <Link href="mailto:privacy@utilifeed.com">privacy@utilifeed.com</Link>
              </Typography>
              <Typography variant="h3" gutterBottom>
                8. Withdrawal of Consent
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 21</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed reserves the legal right on contractual basis to store personal data for
                the duration of the contract. However, if a service processing is based on consent,
                you have the right to withdraw consent at any time.
              </Typography>
              <Typography variant="h3" gutterBottom>
                9. Complaints
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 77</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                You have the right to lodge a complaint with a supervisory authority.
              </Typography>
              <Typography variant="h3" gutterBottom>
                10. Statutory/Contractual Requirement
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 6(1)(b), 6(1)(c), 7.</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                The provision of personal data is necessary for the performance of our services.
                Failure to provide such data may result in an inability to access certain features.
              </Typography>
              <Typography variant="h3" gutterBottom>
                11. Automated Decision-Making
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 22(1), 22(4)</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed does not engage in automated decision-making processes, including
                profiling, that could significantly affect you. We prioritize transparency and
                fairness in our operations, ensuring that decisions are made with human intervention
                to uphold your rights and prevent any potential adverse consequences.
              </Typography>
              <Typography variant="h3" gutterBottom>
                12. Tracking Sensitive Data Access
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR Articles: 6(1)(f)</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Due to the fact that Utilifeed stores GDPR sensitive data, including but not limited
                to: Customer Name, addresses, coordinates and energy usage; Utilifeed reserves the
                right to track access to this data as part of Article 6(1)(f), which covers
                legitimate interests
              </Typography>
              <Typography variant="body1" gutterBottom>
                We track access to this sensitive GDPR data, via analytics provided by PostHog. This
                tracking is essential for monitoring and securing access to sensitive information.
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h2" gutterBottom>
                Cookies and Tracking Notice
              </Typography>
              <Typography variant="h3" gutterBottom>
                <strong>Authentication</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed uses Auth0 and an authentication cookie to keep users logged in. This
                cookie is essential for ensuring that users have a seamless and secure experience
                when accessing our platform. Without this authentication cookie, users would need to
                log in repeatedly during their session, significantly affecting usability and
                functionality.
              </Typography>
              <Typography variant="h3" gutterBottom>
                <strong>Usage Data</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                We collect usage data through Sentry for crash reporting and to make ongoing
                improvements to our platform. This data is crucial for identifying and resolving
                issues swiftly, ensuring a stable and efficient user experience. By understanding
                where and why crashes occur, we can enhance the platform&lsquo;s reliability and
                performance.
              </Typography>
              <Typography variant="h3" gutterBottom>
                <strong>Analytics</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed utilizes PostHog to collect analytics data, which serves two primary
                purposes:
              </Typography>
              <Typography variant="body1" component="ul" gutterBottom>
                <li>
                  <strong>Tracking Sensitive Data Access:</strong> Given the nature of the data we
                  handle, including customer names, addresses, coordinates, and energy usage, it is
                  imperative to monitor who accesses this sensitive information and when. This
                  tracking helps maintain data security and compliance with GDPR requirements.
                </li>
                <li>
                  <strong>Product Improvement:</strong> Analytics data helps us understand how users
                  interact with our platform. By analyzing usage patterns, we can identify areas for
                  improvement and develop features that better meet our users&lsquo; needs. This
                  continuous feedback loop is vital for enhancing the overall user experience.
                </li>
              </Typography>
              <Typography variant="h3" gutterBottom>
                <strong>User Preferences</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                User preferences, such as the last accessed network and filter settings, are stored
                in the local storage of your browser. This functionality allows us to provide a
                personalized experience, ensuring that your preferences are retained between
                sessions for convenience and efficiency.
              </Typography>
              <Typography variant="h3" gutterBottom>
                <strong>Strictly Necessary and Performance Cookies</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                The cookies and tracking mechanisms we employ are strictly necessary for the
                functionality, performance, and improvement of our services. They ensure that the
                platform operates smoothly and that we can continuously enhance its performance
                based on user feedback and data.
              </Typography>
              <Typography variant="h3" gutterBottom>
                <strong>Opting Out</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                You have the option to manage and delete cookies through your browser settings.
                While opting out of certain cookies may impact the functionality and user experience
                of the Utilifeed platform, you retain full control over your data preferences. For
                more information on how to manage cookies, please refer to your browser&lsquo;s
                documentation.
              </Typography>
              <Typography variant="body1" gutterBottom>
                If you choose to opt out of cookies, please note that you may need to log in
                manually each time you visit the platform, and some features may not function as
                intended.
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h2" gutterBottom>
                Updates to this Policy
              </Typography>
              <Typography variant="body1" gutterBottom>
                We periodically review and update this Privacy Policy to reflect new services or
                changes in our practices. The &ldquo;Last Update&rdquo; date at the top of this
                notice indicates when the Policy was most recently revised. We encourage you to
                review this Policy regularly to stay informed about our privacy practices and how
                you can help protect your privacy when interacting with Utilifeed.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Significant changes to this Policy and the way that we handle your data will be
                communicated via email. This is true for when Utilifeed changes third-party access
                to your data, as well as adding or removing sub-processors.
              </Typography>
              <Typography variant="h3" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="body1" gutterBottom>
                If you have any questions or concerns regarding your privacy, please contact us at{" "}
                <Link href="mailto:privacy@utilifeed.com">privacy@utilifeed.com</Link>
              </Typography>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value="se">
          <Box p={4}>
            <Typography variant="h2" gutterBottom>
              Integritetspolicy för plattformen Utilifeed
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Senast uppdaterad: 3 juni 2024
            </Typography>
            <Typography variant="body1" gutterBottom>
              Vi på Utilifeed bryr oss om din personliga integritet och skyddet av dina
              personuppgifter. Den här policyn förklarar hur vi samlar in, använder och delar din
              information i vår plattform Utilifeed. Läs gärna igenom den för att förstå hur vi
              hanterar dina uppgifter.
            </Typography>
            <Typography variant="h3" gutterBottom>
              Kakor och andra spårningstekniker
            </Typography>
            <Typography variant="body1" gutterBottom>
              Utilifeed och våra underbiträden använder kakor och lokal lagring för att ge dig en
              bättre funktionalitet och upplevelse . För mer information, se avsnitt 13. Om kakor
              och spårning där du kan hitta information om hur man kontrollerar eller väljer bort
              kakor eller spårningstekniker.
            </Typography>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h3" gutterBottom>
                1. Kontaktuppgifter
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Organization:</strong> Utilifeed
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Kontaktperson:</strong> CEO Samuel Jansson
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Dataskyddsombud:</strong> Johan Kensby
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>E-post:</strong>{" "}
                <Link href="mailto:johan.kensby@utilifeed.com">johan.kensby@utilifeed.com</Link>
              </Typography>
              <Typography variant="h3" gutterBottom>
                2. Varför samlar vi in data?
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR-artiklar: 6(1)(b)</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Vi behandlar dina personuppgifter för följande ändamål och rättsliga grunder:
              </Typography>
              <Typography variant="body1" component="ul" gutterBottom>
                <li>
                  <strong>Syfte:</strong> För att vi ska kunna ge dig tillgång till vår digitala
                  plattform Utilifeed.
                </li>
                <li>
                  <strong>Rättslig grund:</strong> För att vi ska kunna uppfylla vårt avtal med er.
                </li>
              </Typography>
              <Typography variant="h3" gutterBottom>
                3. Berättigade intressen
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR-artiklar: 6(1)(f)</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed kan komma att behandla dina uppgifter utifrån legitima intressen. Dessa
                inkluderar:
              </Typography>
              <Typography variant="body1" component="ul" gutterBottom>
                <li>Förbättring och utveckling av våra tjänster och av plattformen.</li>
                <li>Hitta och spåra buggar för att skapa ett felfritt system.</li>
                <li>Säkerställa säkerheten för vår plattform.</li>
              </Typography>
              <Typography variant="h3" gutterBottom>
                4. Vilka delar vi data med?
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR-artiklar: 13(1)(e), 44, 46</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Vi delar din data med:
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Auth0:</strong> För autentisering.
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>PostHog:</strong> För analys och förbättringar.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Se under rubriken Kakor och spårning (nedan) för mer information.
              </Typography>
              <Typography variant="h3" gutterBottom>
                5. Dataöverföringar
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR-artiklar: 44, 46, 49</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed prioriterar säkerhet och sekretess för personuppgifter. Överföring av
                personuppgifter till länder utanför EU sker bara när det är absolut nödvändigt
                enligt denna policy. Vi ser till att sådana överföringar följer strikta
                dataskyddsregler och att lämpliga skyddsåtgärder noggrant genomförs för att
                säkerställa integriteten och säkerheten för din information.
              </Typography>
              <Typography variant="h3" gutterBottom>
                6. Hur länge vi behåller data
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR-artiklar: 5(1)(e)</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Din datalagring hanteras noggrant och säkert hos Utilifeed. Vi behåller dina
                personuppgifter bara så länge det behövs för de ändamål som beskrivs i denna policy.
                Lagringstiden bestäms utifrån juridiska krav, avtalsmässiga behov eller om
                uppgifterna behövs för att starta, genomföra eller försvara rättsliga krav.
                Utilifeed prioriterar säker datalagring. All data som lagras av Utilifeed hanteras
                med stor omsorg och krypteras under överföringen.
              </Typography>
              <Typography variant="h3" gutterBottom>
                7. Dina rättigheter
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR-artiklar: 15, 16, 17</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Du har rätt att få tillgång till, rätta och radera dina personuppgifter. För att
                utnyttja dessa rättigheter, kontakta oss på{" "}
                <Link href="mailto:privacy@utilifeed.com">privacy@utilifeed.com</Link>.
              </Typography>
              <Typography variant="h3" gutterBottom>
                8. Återkallande av samtycke
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR-artiklar: 21</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Om vi behandlar dina data baserat på ditt samtycke, kan du när som helst återkalla
                detta samtycke.
              </Typography>
              <Typography variant="h3" gutterBottom>
                9. Klagomål
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR-artiklar: 77</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Du har rätt att klaga hos en tillsynsmyndighet om du är missnöjd med hur vi hanterar
                dina personuppgifter.
              </Typography>
              <Typography variant="h3" gutterBottom>
                10. Nödvändighet av personuppgifter
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR-artiklar: 6(1)(b), 6(1)(c), 7.</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Vi behöver dina personuppgifter för att kunna erbjuda våra tjänster. Om du inte ger
                oss dessa uppgifter kan vissa funktioner bli otillgängliga.
              </Typography>
              <Typography variant="h3" gutterBottom>
                11. Automatiserade beslut
              </Typography>
              <Typography variant="body1" gutterBottom>
                Vi fattar inga viktiga beslut om dig med hjälp av automatiserade system utan
                mänsklig inblandning.
              </Typography>
              <Typography variant="h3" gutterBottom>
                12. Spårning av känslig data
              </Typography>
              <Typography variant="body1" gutterBottom>
                <em>GDPR-artiklar: 6(1)(f)</em>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed lagrar känsliga uppgifter som kundnamn, adresser, koordinater och
                energianvändning. Därför har vi rätt att spåra åtkomst till dessa uppgifter enligt
                GDPR. Vi använder PostHog för att spåra och skydda åtkomst till denna känsliga
                information.
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h2" gutterBottom>
                Kakor och spårning
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Inloggning</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed använder Auth0 samt en kaka för inloggning för att hålla användare
                inloggade. Kakan är viktig för att ge användarna en smidig och säker upplevelse på
                vår plattform. Utan kakan skulle användarna behöva logga in flera gånger under samma
                session, vilket skulle försämra användarvänligheten och funktionaliteten.
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Användningsdata</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Vi samlar in användningsdata genom Sentry för att rapportera kraschar och för att
                förbättra plattformen. Denna data är viktig för att snabbt kunna identifiera och
                lösa problem, vilket säkerställer en stabil och effektiv användarupplevelse. Genom
                att förstå var och varför kraschar uppstår kan vi förbättra plattformens
                tillförlitlighet och prestanda.
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Analys</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Utilifeed använder PostHog för att samla in analysdata, vilket har två huvudsyften:
              </Typography>
              <Typography variant="body1" component="ul" gutterBottom>
                <li>
                  Spårning av åtkomst till känslig data
                  <br />
                  Eftersom vi hanterar känslig information som kundnamn, adresser, koordinater och
                  energianvändning, är det viktigt att övervaka vem som har tillgång till denna
                  information och vid vilken tidpunkt. Detta hjälper oss att upprätthålla
                  datasäkerheten och att följa GDPR-kraven.
                </li>
                <li>
                  Produktförbättring
                  <br />
                  Analysdata hjälper oss att förstå hur användare interagerar med vår plattform.
                  Genom att analysera användningsmönster kan vi identifiera förbättringsområden och
                  utveckla funktioner som bättre uppfyller användarnas behov. Denna kontinuerliga
                  återkoppling är viktig för att förbättra den övergripande användarupplevelsen.
                </li>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Användarinställningar</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Användarinställningar, som senast besökta nätverk och filterinställningar, lagras
                lokalt i din webbläsare. Detta gör att vi kan erbjuda en personlig upplevelse, där
                dina inställningar sparas mellan sessioner både för bekvämlighet och effektivitet.
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Nödvändiga kakor för prestanda</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                De kakor och spårningsmekanismer vi använder är strikt nödvändiga för
                funktionaliteten, prestandan och förbättringen av våra tjänster. De säkerställer att
                plattformen fungerar smidigt och att vi kontinuerligt kan förbättra dess prestanda
                baserat på användarfeedback och data.
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Att välja bort kakor</strong>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Du kan hantera och ta bort kakor genom inställningarna i din webbläsare. Att välja
                bort vissa kakor kan påverka funktionaliteten och användarupplevelsen på
                Utilifeed-plattformen, men du har full kontroll över dina datainställningar. För mer
                information om hur du hanterar kakor, se din webbläsares hjälpdokumentation.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Om du väljer att stänga av kakor, tänk på att du kan behöva logga in manuellt varje
                gång du besöker plattformen och att vissa funktioner kanske inte fungerar som de
                ska.
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h2" gutterBottom>
                Uppdateringar av denna policy
              </Typography>
              <Typography variant="body1" gutterBottom>
                Vi granskar och uppdaterar regelbundet denna integritetspolicy för att återspegla
                nya tjänster eller ändringar i våra rutiner. &ldquo;Senast uppdaterad&rdquo;-datumet
                högst upp i detta meddelande visar när policyn senast ändrades. Vi uppmuntrar dig
                att regelbundet läsa igenom denna policy för att hålla dig informerad om våra
                integritetspraxis och hur du kan hjälpa till att skydda din integritet när du
                använder Utilifeed.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Större ändringar av denna policy och hur vi hanterar dina data kommer att meddelas
                via e-post. Detta gäller även när Utilifeed ändrar tredje parts åtkomst till dina
                data, samt lägger till eller tar bort underbiträden.
              </Typography>
              <Typography variant="h3" gutterBottom>
                Kontakta oss
              </Typography>
              <Typography variant="body1" gutterBottom>
                Om du har några frågor eller funderingar angående din integritet, vänligen kontakta
                oss på <Link href="mailto:privacy@utilifeed.com">privacy@utilifeed.com</Link>.
              </Typography>
            </Box>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default PrivacyPolicy;
