import { useTranslation } from "react-i18next";
import { Alert, type AlertColor } from "@mui/material";
import { Box } from "@mui/system";

import { UtfButton } from "@shared/ui/inputs";

type SimulationStatusProps = {
  status: string;
  label: string;
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
};
export function SimulationStatus({
  status,
  label,
  disabled,
  loading,
  onClick,
}: SimulationStatusProps) {
  const { t } = useTranslation(["_action"]);

  const statusToSeverityMap: { [key: string]: AlertColor } = {
    text_awaiting_configuration: "info",
    text_awaiting_inputs: "info",
    text_ready_to_run: "success",
    text_fetching: "info",
    "optimal solution found": "success",
    // These are from the optimization API
    done: "success",
    in_progress: "info",
  };

  return (
    <Box width="100%">
      <Alert
        severity={statusToSeverityMap[status] || "warning"}
        data-testid="opt-run-simulation-status"
        sx={{
          my: 1,
          textTransform: "capitalize",
        }}
      >
        {label}
      </Alert>
      <UtfButton
        onClick={onClick}
        data-testid="opt-run-simulation"
        disabled={disabled}
        fullWidth
        loading={loading}
      >
        {t("action_run_simulation", { ns: "_action" })}
      </UtfButton>
    </Box>
  );
}
