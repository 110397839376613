import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";

import { useInject } from "@hooks";
import { CompareCard, InfoCard } from "@shared/ui/analytics/cards";
import { Section } from "@shared/ui/layout";

import Charts from "./Charts";
import CostsAndRevenues from "./CostsAndRevenues";
import DeviationsSummaryTable from "./DeviationsSummaryTable";

const renderCompareCard = (title, values, unit) => {
  if (values.length > 1) {
    return <CompareCard title={title} unit={unit} values={values} />;
  }

  return <InfoCard title={title} value={values[0].value} subtitle={values[0].title} unit={unit} />;
};

function HeatingResults({ results, isComparing = false }) {
  const [simulationResults] = useState(results);
  const [result] = useState(results[0]);
  const { networks, preferences } = useInject("networks", "preferences");
  const { t } = useTranslation(["production"]);

  const getSummaryField = (value) =>
    simulationResults.map((simResult) => ({
      title: simResult.summary.configName,
      value: simResult.summary[value],
    }));

  return (
    <Grid container rowSpacing={0}>
      <Grid item xs={12}>
        <DeviationsSummaryTable
          deviations={results[0].active_deviations}
          components={results[0].components}
          showHistorical={true}
        />
      </Grid>
      <Grid item xs={12}>
        <Section
          title={t("text_key_values")}
          collapsible
          titleUpperCase
          isCollapsed={preferences.getSimResultCollapsibleTab("key_values")}
          onCollapseToggleHandler={() => preferences.upsertSimResultCollapsible("key_values")}
        >
          {isComparing ? (
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={6} data-testid="opt-kpi-net-unit-cost-energy">
                {renderCompareCard(
                  t("text_net_cost_per_unit_of_energy"),
                  getSummaryField("netUnitCost"),
                  `${networks.currency}/kWh`
                )}
              </Grid>
              <Grid item xs={6} data-testid="opt-kpi-total-heat-production">
                {renderCompareCard(
                  t("text_total_heat_production"),
                  getSummaryField("totalHeatProduction"),
                  "kWh"
                )}
              </Grid>
              <Grid item xs={12}>
                <CostsAndRevenues results={simulationResults} />
              </Grid>
            </Grid>
          ) : (
            <Box display="flex" flexDirection="column" gap={1}>
              <Box display="flex" gap={1}>
                <Box flex={1} data-maskdata data-testid="opt-kpi-production-cost">
                  <InfoCard
                    value={result.summary.productionCost}
                    title={t("text_production_cost")}
                    unit={networks.currency}
                  />
                </Box>
                <Box flex={1} data-testid="opt-kpi-revenue-sold-electricity">
                  <InfoCard
                    value={result.summary.electricitySoldRevenue}
                    title={t("text_revenue_sold_electricity")}
                    unit={`${networks.currency}`}
                  />
                </Box>
                <Box flex={1} data-testid="opt-kpi-net-unit-cost-energy">
                  <InfoCard
                    value={result.summary.netUnitCost}
                    title={t("text_net_cost_per_unit_of_energy")}
                    unit={`${networks.currency}/kWh`}
                  />
                </Box>
              </Box>
              <Box display="flex" gap={1}>
                <Box flex={1} data-testid="opt-kpi-electricity-generation">
                  <InfoCard
                    value={result.summary.electricityGeneration}
                    title={t("text_electricity_generation")}
                    unit="kWh"
                  />
                </Box>
                <Box flex={1} data-testid="opt-kpi-electricity-total-use">
                  <InfoCard
                    value={result.summary.electricityTotalUse}
                    title={t("text_electricity_use")}
                    unit="kWh"
                  />
                </Box>
                <Box flex={1} data-testid="opt-kpi-heat-generation">
                  <InfoCard
                    value={result.summary.totalHeatProduction}
                    title={t("text_heat_generation")}
                    unit="kWh"
                  />
                </Box>
                <Box flex={1} data-testid="opt-kpi-cooling-generation">
                  <InfoCard
                    value={result.summary.totalCoolingProduction}
                    title={t("text_cooling_generation")}
                    unit="kWh"
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Section>
      </Grid>
      <Grid item xs={12}>
        <Charts results={simulationResults} />
      </Grid>
    </Grid>
  );
}

HeatingResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      summary: PropTypes.shape({
        netUnitCost: PropTypes.number,
        totalHeatProduction: PropTypes.number,
        configName: PropTypes.string,
        productionCost: PropTypes.number,
        electricitySoldRevenue: PropTypes.number,
      }),
      name: PropTypes.string,
    })
  ).isRequired,
  isComparing: PropTypes.bool,
};

export default HeatingResults;
