import { forwardRef } from "react";
import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableRow as MuiTableRow,
  styled,
} from "@mui/material";
import PropTypes from "prop-types";

export const TableContainer = forwardRef(({ children, noPadding }, ref) => (
  <MuiTableContainer sx={noPadding ? {} : { px: 3 }} ref={ref}>
    {children}
  </MuiTableContainer>
));

TableContainer.defaultProps = {
  noPadding: false,
};

TableContainer.propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
};

TableContainer.displayName = "UtfInfoTableContainer";

export const Table = forwardRef(({ children, noBorder, size }, ref) => (
  <MuiTable sx={noBorder ? {} : { border: 1, borderColor: "grey.200" }} size={size} ref={ref}>
    {children}
  </MuiTable>
));

Table.defaultProps = {
  noBorder: false,
  size: "medium",
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
};

Table.displayName = "UtfInfoTable";

export const TableCell = forwardRef(({ children, noBorder, align, sx }, ref) => (
  <MuiTableCell
    sx={{ ...(noBorder ? {} : { border: 1, borderColor: "grey.200" }), ...sx }}
    {...{ align }}
    ref={ref}
  >
    {children}
  </MuiTableCell>
));

TableCell.defaultProps = {
  noBorder: false,
  align: "inherit",
  sx: {},
  children: null,
};

TableCell.propTypes = {
  children: PropTypes.node,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["center", "inherit", "justify", "left", "right"]),
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

TableCell.displayName = "UtfInfoTableCell";

export const TableRow = styled(MuiTableRow, { name: "UtfInfoTableRow" })`
  &:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.palette.grey["100"]};
  }
  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.palette.grey["50"]};
  }
`;
