import { createStore } from "zustand-x";

import { OptComponent, OptComponentType, OptConfig } from "@customTypes/production/optimization";
import { AltCostCollection, AlternativeCostConfigForm } from "@customTypes/production/sales";

import { calculateDemands } from "./utils";

type AlternativeCostStore = {
  selectedCollection: AltCostCollection | null;
  selectedConfiguration: OptConfig | null;
  selectedNetwork: string | null;
  selectedConfigComponents: OptComponent[] | null;
  configForm: AlternativeCostConfigForm | null;
  componentTypes: OptComponentType[];
};

// We also have  a mapping between FE values and Component paramaters
const componentTypeMapping = {
  ALT_COST_BUILDING: {
    heated_area: "heatedArea",
    energy_signature: ["energySignatureA", "energySignatureB", "energySignatureT0"],
    interest_rate: "interestRate",
    radiator_design_temperature: "radiatorDesignTemp",
  },
  ALT_COST_HEAT_PRODUCER: {
    borehole_depreciation_time: "boreholeDepreciation",
    heat_pump_depreciation_time: "heatPumpDepreciation",
    heat_pump_type: "heatPumpType",
    // "installed_heat_pump_capacity": "installedHeatPumpCapacity",
    // "installed_peak_boiler_capacity": "installedPeakBoilerCapacity",
    investment_cost_kwh_heat_pump: "investmentCostHeatPump",
    investment_cost_kwh_borehole: "investmentCostBorehole",
    investment_cost_kwh_boiler: "investmentCostPeakBoiler",
    o_and_m_cost: "omCost",
    peak_boiler_depreciation_time: "peakBoilerDepreciation",
    // peak_boiler_type: "boilerType",
    power_coverage_factor: "powerCoverage",
  },
};

// Here lives client side state for AlternativeCost
// Server side state is managed by Tanstack Query via the API
const alternativeCostStore = createStore<AlternativeCostStore>(
  {
    selectedCollection: null,
    selectedConfiguration: null,
    selectedConfigComponents: null,
    selectedNetwork: null,
    configForm: null,
    componentTypes: [],
  },
  {
    name: "AlternativeCost",
    devtools: {
      name: "AlternativeCost",
      enabled: !import.meta.env.PROD || import.meta.env.MODE !== "test",
    },
  }
)
  // Reset the config
  .extendActions((set) => ({
    resetConfig: (config?: OptConfig) => {
      if (!config) {
        set.selectedConfiguration(null);
      } else {
        set.selectedConfiguration(config);
      }
      set.selectedConfigComponents([]);
      set.configForm(null);
    },
  }))
  // Calculate the values based on the config
  .extendSelectors((state, get) => ({
    calculatedValues: () => {
      const currentCollection = get.selectedCollection();
      const configForm = get.configForm();
      const weatherData = currentCollection?.weatherData || [];

      if (!configForm) {
        console.error("Error calculating values, no config form");
        return;
      }

      if (!currentCollection || !weatherData) {
        console.error("Error calculating values, no collection or outdoor temperatures");
        return;
      }

      return calculateDemands(weatherData, configForm);
    },
  }))
  .extendActions((set, get) => ({
    newFormValues: () => {
      // Get
      const configComponents = get.selectedConfigComponents();
      // We do the inverse of the calculateComponents function
      // We take the components and calculate the values

      // We start with the default values
      const values: AlternativeCostConfigForm = {
        buildingType: "Custom building",
        heatedArea: 0,
        energySignatureA: 0,
        energySignatureB: 0,
        energySignatureT0: 0,
        interestRate: 0,
        radiatorDesignTemp: "60/40",
        boreholeDepreciation: 0,
        heatPumpType: "Ground Source Heat Pump",
        heatPumpDepreciation: 0,
        investmentCostHeatPump: 0,
        investmentCostBorehole: 0,
        omCost: 0,
        peakBoilerDepreciation: 0,
        investmentCostPeakBoiler: 0,
        powerCoverage: 60,
        peakPumpType: "Electric",
      };

      if (!configComponents?.length) {
        set.configForm(values);
        // If there are no components, we return default values
        return values;
      }

      // For each component, we update the values
      configComponents.forEach((component) => {
        const componentType = component.type;
        const componentParams = component.properties;
        const componentParamsMap: Record<string, string> =
          // @ts-expect-error - We know this is a valid type
          componentTypeMapping[componentType] || {};
        Object.entries(componentParamsMap).forEach(([key, value]) => {
          // @ts-expect-error - This needs cleanup
          const param = componentParams.find((p) => p.technicalName === key);
          if (param) {
            // energy_signature is a special case
            if (key === "energy_signature") {
              if (!param.value) {
                return;
              }
              // it returns an array of numbers
              values.energySignatureA = Number(param.value[0]);
              values.energySignatureB = Number(param.value[1]);
              values.energySignatureT0 = Number(param.value[2]);
            } else {
              // @ts-expect-error - We know this is a valid type
              values[value] = param.value;
            }
          }
        });
      });

      set.configForm(values);
    },
  }));

export default alternativeCostStore;
