import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DateTime } from "luxon";

import { SimulationStatus } from "@components/Optimization/Simulation/SimulationStatus";
import { BaseCard } from "@shared/ui/analytics/cards";
import UtfDateRangePicker from "@shared/ui/inputs/UtfDateRangePicker/UtfDateRangePicker";

const PeriodRow = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  flexDirection: "column",
  marginBottom: theme.spacing(1),
  "& > *": {
    marginBottom: theme.spacing(2),
  },
}));

export type SimulationOptionsProps = {
  fetching: boolean;
  status: string;
  canRun: boolean;
  runSimulationCallback: (params: {
    simulationStartDate: DateTime;
    simulationEndDate: DateTime;
    trainingStartDate: DateTime;
    trainingEndDate: DateTime;
  }) => void;
  defaultTrainStart?: DateTime;
  defaultTrainEnd?: DateTime;
  defaultSimStart?: DateTime;
  defaultSimEnd?: DateTime;
};

export function SimulationOptions({
  fetching,
  status,
  canRun,
  runSimulationCallback,
  defaultTrainStart,
  defaultTrainEnd,
  defaultSimStart,
  defaultSimEnd,
}: SimulationOptionsProps) {
  const getMinDate = (year: number) => DateTime.fromObject({ year, month: 1, day: 1 });
  const { t } = useTranslation(["production", "_action"]);

  // Default scenario periods from props or fallback to computed dates
  const initialTrainStart =
    defaultTrainStart ?? DateTime.now().minus({ months: 18 }).startOf("month");
  const initialTrainEnd = defaultTrainEnd ?? DateTime.now().minus({ months: 1 }).endOf("month");
  const initialSimStart = defaultSimStart ?? DateTime.now().minus({ months: 1 }).startOf("month");
  const initialSimEnd =
    defaultSimEnd ?? DateTime.now().startOf("month").minus({ months: 1 }).endOf("month");

  // Training Period
  const [trainingStartDate, setTrainingStartDate] = useState(initialTrainStart);
  const [trainingEndDate, setTrainingEndDate] = useState(initialTrainEnd);
  // Simulation Period
  const [simulationStartDate, setSimulationStartDate] = useState(initialSimStart);
  const [simulationEndDate, setSimulationEndDate] = useState(initialSimEnd);

  useEffect(() => {
    // If needed, lift state changes via a callback here.
  }, [trainingStartDate, trainingEndDate, simulationStartDate, simulationEndDate]);

  const onRunClick = () => {
    runSimulationCallback({
      simulationStartDate: simulationStartDate.startOf("month"),
      simulationEndDate: simulationEndDate.endOf("month"),
      trainingStartDate: trainingStartDate.startOf("month"),
      trainingEndDate: trainingEndDate.endOf("month"),
    });
  };

  return (
    <BaseCard title={t("text_simulation_settings", { ns: "production" })}>
      <Box
        sx={{
          width: "100%",
          p: [0, 3],
        }}
      >
        <div>
          {/* Training Period */}
          <PeriodRow>
            <UtfDateRangePicker
              startDate={trainingStartDate}
              endDate={trainingEndDate}
              onChange={(start, end) => {
                setTrainingStartDate(start);
                setTrainingEndDate(end?.endOf("month").startOf("day"));
              }}
              disabled={fetching}
              dateFormat="dd/MM/yyyy"
              minDate={getMinDate(2016)}
              maxDate={DateTime.local().startOf("month").minus({ months: 1 })}
              resolution="month"
              monthsShown={1}
              placeholder={t("text_training_period", { ns: "production" })}
              size="large"
              isClearable
            />
          </PeriodRow>

          {/* Simulation Period */}
          <PeriodRow>
            <UtfDateRangePicker
              startDate={simulationStartDate}
              endDate={simulationEndDate}
              onChange={(start, end) => {
                setSimulationStartDate(start);
                setSimulationEndDate(end?.endOf("month").startOf("day"));
              }}
              disabled={fetching}
              dateFormat="dd/MM/yyyy"
              minDate={getMinDate(2000)}
              maxDate={DateTime.local().startOf("month").minus({ months: 1 })}
              resolution="month"
              monthsShown={1}
              placeholder={t("text_simulation_period", { ns: "production" })}
              size="large"
              isClearable
            />
          </PeriodRow>
        </div>

        {/* Status */}
        <SimulationStatus
          status={status}
          label={t(status)}
          disabled={!canRun || fetching}
          loading={fetching}
          onClick={onRunClick}
        />
      </Box>
    </BaseCard>
  );
}

export default observer(SimulationOptions);
