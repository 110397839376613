/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useMemo, useRef, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider, Drawer } from "@mui/material";
import { styled, useTheme, type Theme } from "@mui/material/styles";
import * as Highcharts from "highcharts/highstock";
import { DateTime, type DateTime as DateTimeT } from "luxon";

import { DATE_FORMAT, DATETIME_FORMAT_DEFAULT, INTERVAL_HOURLY, INTERVAL_OPTIONS } from "@config";
import { logger as baseLogger } from "@core/logger";
import { useDebouncedValue } from "@hooks";

import { ChartNavigator } from "./ChartNavigator";
import { ButtonDropdown } from "./ui/inputs/ButtonDropdown";
import { FilterBarDatePicker } from "./ui/inputs/FilterBarDatePicker/FilterBarDatePicker";
import withErrorBoundary from "./ui/withErrorBoundary";

const logger = baseLogger.getSubLogger({ name: "<FilterBar/>" });

const ROW_HEIGHT = 56;

const Row = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  width: "100%",
  minHeight: ROW_HEIGHT,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxShadow: theme.shadows[1],
  gap: theme.spacing(2),
}));

const ToggleButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.8),
  boxShadow: theme.shadows[1],
  cursor: "pointer",
  svg: {
    color: "currentcolor",
  },
}));

const Section = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "auto",
  height: "100%",
  alignItems: "center",
  color: theme.palette.grey["50"],
  gap: theme.spacing(1),
}));

const DrawerBody = styled("div")(({ theme }) => ({
  color: "white",
  display: "flex",
  overflow: "hidden",
  backgroundColor: theme.palette.primary.main,
}));

const VerticalDivider = () => (
  <Divider orientation="vertical" variant="inset" flexItem sx={{ width: "2px", margin: 0 }} />
);

/**
 * Gets the time range from an array of production data points
 * @param data - Array of production data points
 * @returns Object containing start and end dates, or null values if data is empty
 */
export const getTimeRange = (data: ProductionDataPoint[]): DateRange => {
  if (!data?.length) return { start: null, end: null };

  const start = DateTime.fromISO(data[0].datetime);
  const end = DateTime.fromISO(data[data.length - 1].datetime)
    .endOf("day")
    .startOf("hour");

  if (!start.isValid || !end.isValid) {
    throw new Error("Invalid datetime format in data points");
  }

  return {
    start,
    end,
  };
};

function openedMixin(theme: Theme) {
  return {
    height: `calc(${ROW_HEIGHT} * 2 + 1px)`,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  };
}

function closedMixin(theme: Theme) {
  return {
    height: `calc(${ROW_HEIGHT} + 1px)`,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  };
}

const FilterDrawer = ({ children, width = "100%", ...props }) => {
  const theme = useTheme();
  const paperDefaults = useMemo(
    () => ({
      width,
      boxSizing: "border-box",
      left: "initial",
    }),
    [width]
  );

  return (
    <Drawer
      variant="permanent"
      anchor="bottom"
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        position: "fixed",
        width: "100%",
        right: 0,
        zIndex: 3,
        flexShrink: 0,
        ...(props.open && {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": {
            ...paperDefaults,
            ...openedMixin(theme),
            "& > div": {
              ...openedMixin(theme),
            },
          },
        }),
        ...(!props.open && {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": {
            ...paperDefaults,
            ...closedMixin(theme),
            "& > div": {
              ...closedMixin(theme),
            },
          },
        }),
      }}
      {...props}
    >
      {children}
    </Drawer>
  );
};

type FilterBarProps = {
  data: { [key: string]: number }[] | undefined;
  dataColumn?: string;
  disabled: boolean;
  onChange: (start: DateTimeT, end: DateTimeT) => void;
  onIntervalChange?: (nextInterval: string) => void;
  afterSetExtremes: (extremes: { min: number; max: number }) => void;
  chartOptions?: Highcharts.Options;
  width?: string;
  isLoading?: boolean;
};

const FilterBarComponent: FC<FilterBarProps> = ({
  data,
  dataColumn,
  disabled,
  onChange,
  onIntervalChange,
  afterSetExtremes,
  registerChart,
  width = "100%",
  chartOptions,
  isLoading = false,
}) => {
  const navigatorChartRef = useRef();
  const { t } = useTranslation(["extendView"]);
  const [open, setOpen] = useState(true);
  const [interval, setInterval] = useState(INTERVAL_HOURLY);
  const range = useMemo(() => {
    return getTimeRange(data || []);
  }, [data]);
  const [extremes, setExtremes] = useState([range.start?.toMillis?.(), range.end?.toMillis?.()]);

  useEffect(() => {
    if (range.start && range.end) {
      setExtremes([range.start.toMillis(), range.end.toMillis()]);
    }
  }, [range.start, range.end]);

  const onExpandToggle = useCallback(() => {
    setOpen((isOpen) => !isOpen);
  }, []);

  const [afterMin, afterMax] = useDebouncedValue(extremes, 600);

  useEffect(() => {
    if (afterSetExtremes) afterSetExtremes({ min: afterMin, max: afterMax });
  }, [afterMax, afterMin, afterSetExtremes]);

  const handleAfterSetExtremes = useCallback(
    ({ min, max }: { min: number; max: number }) => {
      setExtremes([min, max]);
      if (onChange) {
        const start = DateTime.fromMillis(min);
        const end = DateTime.fromMillis(max);
        onChange(start, end);
      }
    },
    [onChange]
  );

  const DATA_PICKER_VIEW = [
    "year",
    "month",
    "day",
    ...(interval === INTERVAL_HOURLY ? ["hours"] : []),
  ];

  const handleDateChange = useCallback(
    (newDate: DateTime, isStartDate: boolean) => {
      if (!newDate) return;

      let adjustedDate = newDate;
      const dateMillis = isStartDate ? extremes[1] : extremes[0];

      if (!dateMillis) return;
      const startOrEndDate = DateTime.fromMillis(dateMillis);

      if (isStartDate && newDate.toMillis() > dateMillis) {
        adjustedDate = startOrEndDate.minus({ hours: 6 });
      } else if (!isStartDate && newDate.toMillis() < dateMillis) {
        adjustedDate = startOrEndDate.plus({ hours: 6 });
      }

      const newStart = isStartDate ? adjustedDate : startOrEndDate;
      const newEnd = isStartDate ? startOrEndDate : adjustedDate;

      onChange(newStart, newEnd);
      setExtremes([newStart.toMillis(), newEnd.toMillis()]);
    },
    [onChange, extremes]
  );

  const handleIntervalChange = useCallback(
    (nextInterval: string) => {
      if (!onIntervalChange) return;
      logger.trace("handleIntervalChange", nextInterval);
      setInterval(nextInterval);
      onIntervalChange(nextInterval);
    },
    [onIntervalChange]
  );

  useEffect(() => {
    if (!registerChart || !navigatorChartRef.current || !data) return;
    registerChart("navigator", navigatorChartRef);
  }, [data, navigatorChartRef, registerChart]);

  // Memoize custom chart options
  const customChartOptions = useMemo(
    () => ({
      ...chartOptions,
      plotOptions: {
        ...chartOptions?.plotOptions,
        series: {
          ...chartOptions?.plotOptions?.series,
          animation: false,
          turboThreshold: 5000,
        },
      },
    }),
    [chartOptions]
  );

  if (!range.start || !range.end) {
    logger.error("FilterBar: range.start or range.end is missing");
    return null;
  }

  logger.trace("RENDER %j", { open, range, interval, afterMin, afterMax });

  return (
    <FilterDrawer open={open} width={width} data-testid="floating-filterbar">
      <DrawerBody>
        <Box flex={1}>
          {/* First, always-visible row */}
          <Row>
            <Section sx={{ minWidth: "100%" }}>
              {data && dataColumn && !isLoading && (
                <ChartNavigator
                  data={data}
                  dataColumn={dataColumn}
                  range={range}
                  chartRef={navigatorChartRef}
                  afterSetExtremes={handleAfterSetExtremes}
                  chartOptions={customChartOptions}
                />
              )}
            </Section>
          </Row>

          {/* Second, hideable row */}
          {open && (
            <Row data-testid="floating-filterbar-hideable">
              <Box flex={1} />
              <VerticalDivider />
              {/* Intervals */}
              <Section>
                <Box>{t("extendView:text_resolution")} :</Box>
                <ButtonDropdown
                  options={INTERVAL_OPTIONS}
                  selectedValue={interval}
                  onChange={(nextInterval) => handleIntervalChange(nextInterval)}
                  disabled={disabled}
                  variant="outlined"
                  placement="top"
                  data-testid="interval-btn"
                  translationNs="_common"
                />
              </Section>
              <VerticalDivider />
              <Section>
                <FilterBarDatePicker
                  id="extremes-start"
                  value={DateTime.fromMillis(extremes[0])}
                  onChange={(date) => handleDateChange(date, true)}
                  views={DATA_PICKER_VIEW}
                  format={interval === INTERVAL_HOURLY ? DATETIME_FORMAT_DEFAULT : DATE_FORMAT}
                />
                <Box width={8} height={2} sx={{ backgroundColor: "white" }} />
                <FilterBarDatePicker
                  id="extremes-end"
                  value={DateTime.fromMillis(extremes[1])}
                  onChange={(date) => handleDateChange(date, false)}
                  views={DATA_PICKER_VIEW}
                  format={interval === INTERVAL_HOURLY ? DATETIME_FORMAT_DEFAULT : DATE_FORMAT}
                />
              </Section>
            </Row>
          )}
        </Box>
        {/* Expansion Toggler */}
        <ToggleButton onClick={onExpandToggle}>
          <ExpandMoreIcon sx={{ transform: open ? undefined : "rotate(180deg)" }} />
        </ToggleButton>
      </DrawerBody>
    </FilterDrawer>
  );
};

FilterBarComponent.displayName = "Floating.FilterBar";

export const FilterBar = withErrorBoundary<FilterBarProps>(FilterBarComponent);
