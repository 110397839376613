import { useState } from "react";
import { Collapse } from "@mui/material";
import { Box } from "@mui/system";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";

import { FontAwesomeSvgIcon } from "../../FontAwesomeSvgIcon";
import HeadingBar from "../HeadingBar/HeadingBar";

type CollapsibleSectionProps = {
  title: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  collapsible?: boolean;
  contentFull?: boolean;
  titleUpperCase?: boolean;
  isCollapsed?: boolean;
  onCollapseToggleHandler?: () => void;
  "data-testid"?: string;
};
function Section({
  title,
  children,
  icon,
  collapsible,
  contentFull,
  titleUpperCase,
  onCollapseToggleHandler,
  isCollapsed,
  "data-testid": testId,
}: Readonly<CollapsibleSectionProps>) {
  const [isOpen, setIsOpen] = useState(isCollapsed);
  const addons = [];

  const handleToggle = () => {
    setIsOpen(!isOpen);
    onCollapseToggleHandler?.();
  };

  if (collapsible) {
    addons.push({
      action: (
        <FontAwesomeSvgIcon
          icon={isOpen ? faChevronUp : faChevronDown}
          sx={{ fontSize: 14, color: "primary.dark" }}
        />
      ),
    });
  }

  return (
    <Box mb={2} data-testid={testId}>
      <HeadingBar
        icon={icon}
        title={title}
        addons={addons}
        onClick={collapsible ? handleToggle : null}
        titleUpperCase={titleUpperCase}
        sx={{
          ":hover": { cursor: collapsible ? "pointer" : "default" },
          mb: contentFull ? "1px" : 1,
        }}
        className={contentFull ? "content-full" : ""}
        data-testid={`${title}-heading-bar-container`}
      />
      <Collapse in={isOpen}>
        <Box sx={contentFull ? {} : { sx: { px: 1 } }}>{children}</Box>
      </Collapse>
    </Box>
  );
}

export default Section;
