/* eslint-disable react/no-this-in-sfc */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import { formatNumberForLocale } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { useInject } from "@hooks";
import { HighchartsBase } from "@shared/ui/analytics/charts";

const HEIGHT = 600;

function MarginalCostGraph({ results }) {
  const theme = useTheme();
  const { t } = useTranslation(["production"]);
  const { networks } = useInject("networks");

  const [visibleSeries, setVisibleSeries] = useState({
    variableCostHeating: true,
    variableCostCooling: true,
  });

  const series = [];
  const pointStart = DateTime.fromISO(results.input_parameters.start_time).toMillis();
  const pointInterval = 3600000;
  const type = "area";
  const yAxis = 0;

  if (results.marginal_cost.heat_marginal_cost) {
    series.push({
      name: t("text_variable_cost_heating"),
      data: Object.values(results.marginal_cost.heat_marginal_cost).map((x) => x * 1000),
      color: theme.palette.graph.red,
      type,
      yAxis,
      pointInterval,
      pointStart,
      id: "variableCostHeating",
      visible: visibleSeries.variableCostHeating,
    });
  }

  if (results.summary.hasCoolingDemand && results.marginal_cost.cooling_marginal_cost) {
    series.push({
      name: t("text_variable_cost_cooling"),
      data: Object.values(results.marginal_cost.cooling_marginal_cost).map((x) => x * 1000),
      color: theme.palette.graph.blue,
      type,
      yAxis,
      pointInterval,
      pointStart,
      id: "variableCostCooling",
      visible: visibleSeries.variableCostCooling,
    });
  }

  if (series.length === 0) {
    return null;
  }

  return (
    <HighchartsBase
      series={series}
      chart={{
        type: "area",
        height: HEIGHT,
      }}
      plotOptions={{
        series: {
          marker: false,
          events: {
            legendItemClick: createSeriesToggleHandler(setVisibleSeries),
          },
        },
      }}
      xAxis={{
        title: {
          text: "Time",
        },
        type: "datetime",
      }}
      yAxis={[
        {
          title: {
            text: "",
          },
          resize: {
            enabled: true,
          },
          yUnit: `${networks.currency}/MWh`,
        },
      ]}
      tooltip={{
        pointFormatter() {
          return `<span style="color:${this.color}">\u25CF</span> ${
            this.series.name
          }: <b>${formatNumberForLocale(this.y)} ${networks.currency}/MWh</b><br/>`;
        },
      }}
      legend={{ enabled: true, layout: "horizontal", align: "center", verticalAlign: "bottom" }}
      disableBoost
    />
  );
}

export default observer(MarginalCostGraph);
