import { useCallback, useEffect, useState } from "react";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Papa from "papaparse";

import PriceChartModal from "./ChartModal";

type UtfFileReaderProps = {
  onDataRead: (values: number[], fileName: string) => void;
  accept?: string;
  initialData?: number[];
  initialFileName?: string;
  chartTitle?: string;
};

function UtfFileReader({
  onDataRead,
  accept = ".csv",
  chartTitle = "Electricity Trade Price",
  initialData = [],
  initialFileName = "",
}: Readonly<UtfFileReaderProps>) {
  const [dragActive, setDragActive] = useState(false);
  const [fileName, setFileName] = useState<string>(initialFileName || "");
  const [error, setError] = useState<string>("");
  const [isUploaded, setIsUploaded] = useState(initialData.length > 0);
  const [priceData, setPriceData] = useState<number[]>(initialData || []);

  const useStyles = makeStyles<Theme>((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    dropzone: {
      width: "100%",
      border: `2px dashed ${dragActive ? theme.palette.primary.main : theme.palette.divider}`,
      borderRadius: `${theme.shape.borderRadius}px`,
      padding: theme.spacing(1),
      textAlign: "center" as const,
      backgroundColor: dragActive ? theme.palette.action.hover : "transparent",
    },
    errorText: {
      color: theme.palette.error.main,
      marginTop: theme.spacing(1),
      fontSize: "0.875rem",
    },
    browseText: {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    fileName: {
      color: theme.palette.text.primary,
    },
    helpText: {
      marginTop: theme.spacing(1),
      fontSize: "0.875rem",
    },
    successText: {
      color: theme.palette.success.main,
      marginBottom: theme.spacing(1),
    },
    fileNameContainer: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
      justifyContent: "center",
    },
    removeButton: {
      border: "none",
      background: "none",
      color: theme.palette.text.primary,
      cursor: "pointer",
    },
  }));

  const classes = useStyles();

  // Validation and parsing functions
  const validateData = useCallback((values: number[]): boolean => {
    if (values.length !== 8760) {
      setError(`Expected 8760 values (hours in a year), but got ${values.length} values`);
      return false;
    }

    const hasInvalidValues = values.some((val) => typeof val !== "number" || isNaN(val));
    if (hasInvalidValues) {
      setError("File contains invalid numeric values");
      return false;
    }

    return true;
  }, []);

  useEffect(() => {
    if (priceData.length) return;
    if (initialData && initialData.length > 0) {
      if (validateData(initialData)) {
        setPriceData(initialData);
        setIsUploaded(true);
        onDataRead(initialData, initialFileName || "");
      }
    }
  }, [initialData, initialFileName, onDataRead, priceData, validateData]);

  const parseFileContent = useCallback((text: string): number[] | null => {
    const parseResult = Papa.parse(text.trim(), {
      skipEmptyLines: true,
      dynamicTyping: true,
    });

    const values = parseResult.data
      .map((row: unknown) => {
        // Ensure we're only taking the second column if it exists
        return Array.isArray(row) && row.length > 1 ? parseFloat(row[1]) : parseFloat(row[0]);
      })
      .filter((val: number) => !isNaN(val));

    return values.length > 0 ? values : null;
  }, []);

  const processFile = useCallback(
    async (file: File) => {
      setError("");
      setFileName(file.name);
      setIsUploaded(false);

      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        const text = e.target?.result as string;
        const values = parseFileContent(text);

        if (!values) {
          setError("Unable to parse file. Please check the format.");
          return;
        }

        if (validateData(values)) {
          setIsUploaded(true);
          setPriceData(values);
          onDataRead(values, file.name);
        }
      };

      fileReader.onerror = () => {
        setError("Error reading file");
      };

      fileReader.readAsText(file);
    },
    [onDataRead, validateData, parseFileContent]
  );

  const handleDrag = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === "dragenter" || e.type === "dragover");
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);

      const file = e.dataTransfer.files?.[0];
      if (file?.name.endsWith(".csv")) {
        void processFile(file);
      }
    },
    [processFile]
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        void processFile(file);
      }

      event.target.value = "";
    },

    [processFile]
  );

  const handleRemoveFile = useCallback(() => {
    setFileName("");
    setIsUploaded(false);
    setError("");
    setPriceData([]);
    onDataRead([], "");
  }, [onDataRead]);

  const buttonContent = isUploaded ? (
    <div data-testid="utf-file-reader-uploaded">
      <div className={classes.successText}>Uploaded</div>
      <div className={classes.fileNameContainer}>
        <div className={classes.fileName}>{fileName}</div>
        <button
          onClick={handleRemoveFile}
          className={classes.removeButton}
          aria-label="Remove file"
          data-testid="utf-file-reader-remove"
          type="button"
        >
          <FontAwesomeIcon icon={faCircleXmark} />
        </button>
      </div>
    </div>
  ) : (
    <>
      <div>Drag & Drop</div>
      <div>or</div>
      <label>
        <span className={classes.browseText}>Browse files</span>
        <input
          type="file"
          onChange={handleFileChange}
          accept={accept}
          style={{ display: "none" }}
          data-testid="utf-file-reader-input"
        />
      </label>
      {error && (
        <div className={classes.errorText} data-testid="utf-file-reader-error">
          {error}
        </div>
      )}
    </>
  );

  return (
    <Box className={classes.root}>
      <button
        type="button"
        className={classes.dropzone}
        data-testid="utf-file-reader-dropzone"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {buttonContent}
      </button>

      <div className={classes.helpText}>Supported file formats: .csv</div>

      {isUploaded && <PriceChartModal data={priceData} chartTitle={chartTitle} />}
    </Box>
  );
}

export default UtfFileReader;
