/* eslint-disable @typescript-eslint/no-explicit-any */
import type { MutableRefObject } from "react";
import type { HighchartsReactRefObject } from "highcharts-react-official";
import { DateTime, Settings } from "luxon";
import { create, type StateCreator } from "zustand";

import {
  DEFAULT_CURRENCY,
  DEFAULT_NETWORK_TIMEZONE,
  DEFAULT_NETWORK_TIMEZONE_NAME,
  getValueFromBlock,
  NETWORK_BLOCK_TYPES as NBT,
} from "@config";
import {
  getInfoBlockQueryOptions,
  getLatestProcessedMonth,
  getLatestProcessedYear,
  logger,
  queryClient,
} from "@core";
import type { Network } from "@core";

import { zustandMiddlware } from "./middleware";

type ChartRef = MutableRefObject<HighchartsReactRefObject>;

type ChartRefs = {
  [key in "primary" | "navigator"]?: ChartRef;
};

type State = {
  selectedNetworkId?: string;
  selectedSubstationId?: string;
  selectedSLId?: string;
  fetchedRange: { start: DateTime; end: DateTime };
  filterRange: { start: DateTime; end: DateTime };
  error?: Error;
  ready: boolean;
  networks: Network[];
  /** timezone */
  tz: string;
  /** timezoneName */
  tzName: string;
  currency: string | null;
  coordinates: string | null;
  currentClusters?: Map<string, string>;
  activeClusters?: Map<string, string>;
  networkClusters?: Map<string, { name: string; substations: any[] }>;
  /** Last Processed Year */
  lpYear: DateTime | null;
  /** Last Processed Month */
  lpMonth: DateTime | null;
  meteringLatestUpload?: DateTime;
  currentHour?: DateTime;
  activeYear?: number;
  activeMonth?: [number, number];
  activeWeek?: number | null;
  chartRefs: ChartRefs | null;
};

type BlockValue = string & number & number[];

type Action = {
  setSelectedNetworkId: (selectedNetworkId: State["selectedNetworkId"]) => void;
  setSelectedSubstationId: (selectedSubstationId: State["selectedSubstationId"]) => void;
  setNetworks: (networks: Network[]) => void;
  selectNetwork: (networkId: string) => Promise<string | undefined>;
  updateNetwork: (nextNetwork: string) => Promise<undefined>;
  setSelectedSLId: (selectedSLId: string) => void;
  setFetchedRange: (fetchedRange: State["fetchedRange"]) => void;
  setFilterRange: (filterRange: State["filterRange"]) => void;
  setupLocale: () => Promise<any> | void;
  updateResources: () => Promise<any> | void;
  getInfoBlock: (
    colName: string,
    infoBlock: any | null,
    block: any | null,
    defaultValue?: BlockValue
  ) => BlockValue | null | undefined;
  refreshTime: () => void;
  registerChart: (chartId: "primary" | "navigator", ref: any) => void;
  setError: (error: Error) => void;
  setReady: (ready: boolean) => void;
  reset: () => void;
};

export type NetworkStoreSlice = State & Action;

const DEFAULT_NETWORK_ID = "demo_network_500";
const INITIAL_RANGE = {
  start: DateTime.local().minus({ month: 3 }).startOf("month"),
  end: DateTime.local().minus({ day: 1 }).endOf("day"),
};

const initialState: State = {
  filterRange: INITIAL_RANGE,
  fetchedRange: INITIAL_RANGE,
  currency: DEFAULT_CURRENCY,
  tz: DEFAULT_NETWORK_TIMEZONE,
  tzName: DEFAULT_NETWORK_TIMEZONE_NAME,
  networks: [],
  coordinates: null,
  ready: false,
  lpYear: null,
  lpMonth: null,
  chartRefs: {},
};

const networkStore: StateCreator<
  NetworkStoreSlice,
  [["zustand/devtools", never]],
  [],
  NetworkStoreSlice
> = (set, get) => ({
  ...initialState,

  // Computed
  get currentHour() {
    return DateTime.now().startOf("hour");
  },

  // Actions
  reset: () =>
    set(
      {
        ready: false,
        selectedNetworkId: undefined,
        currentClusters: undefined,
        chartRefs: {},
      },
      undefined,
      "reset"
    ),
  setError: (error) => set({ error }, undefined, "setError"),
  setReady: (ready) => set({ ready, error: undefined }, undefined, "setReady"),
  setSelectedNetworkId: (selectedNetworkId) =>
    set({ selectedNetworkId }, undefined, "selectedNetworkId"),
  setSelectedSubstationId: (selectedSubstationId) =>
    set({ selectedSubstationId }, undefined, "setSelectedSubstationId"),
  setNetworks: (networks) => set({ networks }, undefined, "setNetworks"),
  setSelectedSLId: (selectedSLId) => set({ selectedSLId }, undefined, "setSelectedSLId"),
  setFetchedRange: (fetchedRange) =>
    set({ fetchedRange, filterRange: fetchedRange }, undefined, "setFetchedRange"),
  setFilterRange: (filterRange) => set({ filterRange }, undefined, "setFilterRange"),

  selectNetwork: async (nextNetworkId) => {
    const { networks } = get();
    // noop if selects the same network
    if (get().selectedNetworkId === nextNetworkId) return;
    logger.trace("selectNetwork", nextNetworkId);
    get().reset();

    let updatedId;
    if (nextNetworkId && networks.some(({ uid }) => uid === nextNetworkId)) {
      // use nextCurrentNetwork provided, if its valid
      updatedId = nextNetworkId;
    } else if ((networks || []).length > 0) {
      // Select predefined default network or pick the first one
      updatedId = (networks.find(({ name }) => name === DEFAULT_NETWORK_ID) || networks[0]).uid;
    }

    if (updatedId) {
      await get().updateNetwork(updatedId);
      return updatedId;
    }
  },

  updateNetwork: async (nextNetworkId) => {
    if (get().ready || !nextNetworkId) return;
    if (get().selectedNetworkId === nextNetworkId) return;

    logger.debug("updateNetwork", nextNetworkId);
    get().setSelectedNetworkId(nextNetworkId);
    try {
      await get().setupLocale();
      await get().updateResources();
      get().setReady(true);
    } catch (err) {
      logger.error("updateNetwork failed");
      console.error(err);
      get().setError(err as Error);
    }
  },

  updateResources: async () => {
    if (get().ready || !get().selectedNetworkId) return;
    logger.debug("updateResources");
    const output: any = {};
    try {
      // For every cluster, we add go and fetch the data .getClusterSubstations(clusterUid)
      // and then we add the info to networkClusters
      //! Disabled for performance reasons. Enable it whenever its needed.
      // try {
      //   if (output.currentClusters.size > 0) {
      //     output.networkClusters = new Map();
      //     for (const [clusterUid, clusterName] of output.currentClusters) {
      //       const clusterData = await queryClient.ensureQueryData(
      //         getClusterSubstationsOptions(clusterUid)
      //       );
      //       if (!clusterData) return;
      //       output.networkClusters.set(clusterUid, {
      //         name: clusterName,
      //         substations: clusterData.substations,
      //       });
      //     }
      //   }
      // } catch (err) {
      //   logger.error("cannot update networkClusters");
      //   console.error(err);
      // }
      return set(output, undefined, "setResources");
    } catch (err) {
      logger.error("cannot update network resources");
      console.error(err);
    }
  },

  setupLocale: async () => {
    if (get().ready || !get().selectedNetworkId) return;

    logger.debug("setupLocale");
    try {
      const data = await queryClient.ensureQueryData(
        getInfoBlockQueryOptions({
          resource_type: "network",
          resource_id: get().selectedNetworkId as string,
          block_names: [
            NBT.ufint_latest.to_block_name(),
            NBT.lava_calc.to_block_name(),
            NBT.location.to_block_name(),
            NBT.metering_latest_upload.to_block_name(),
          ],
        })
      );
      const { getInfoBlock } = get();
      const updates: Record<string, null | undefined> = {
        currency: getInfoBlock("currency", data, NBT.location, DEFAULT_CURRENCY as BlockValue),
        tzName: getInfoBlock("tz_name", data, NBT.location, DEFAULT_NETWORK_TIMEZONE as BlockValue),
        tz: getInfoBlock(
          "network_timezone",
          data,
          NBT.location,
          DEFAULT_NETWORK_TIMEZONE as BlockValue
        ),
        coordinates: getInfoBlock("weather_coordinates", data, NBT.location),
        activeYear: getInfoBlock("core_year", data, NBT.ufint_latest),
        activeMonth: getInfoBlock("core_month", data, NBT.ufint_latest),
        activeWeek: getInfoBlock("week_nr", data, NBT.ufint_latest),
        meteringLatestUpload: getInfoBlock("valid_time", data, NBT.metering_latest_upload),
        epMonth: getInfoBlock("ep_month", data, NBT.ufint_latest),
        epMonthClusters: getInfoBlock("ep_month_clusters", data, NBT.ufint_latest),
      };
      set(updates);
    } catch (err) {
      logger.debug("setupLocale failed", err);
      console.error(err);
    }

    /**
     * Set application-wide default timezone from the network
     * This is used for all date/time display and calculations
     *
     * We dont want to use the UTC offset from the network, because it does not take into account
     * daylight saving time. Instead we use the tz_name, which is a valid IANA timezone name.
     * This is needed to for making Luxon and MUIx DatePickers aware of the DTS (Daylight Saving Time) offset.
     */

    // First we just check if the tz_name is valid
    // If not, we just use the default timezone
    let setTimezone = get().tzName;
    if (!DateTime.local().setZone(setTimezone).isValid) {
      setTimezone = DEFAULT_NETWORK_TIMEZONE.toLowerCase();
      logger.error(
        `Network timezone is invalid: ${get().tzName}. Using default timezone: ${DEFAULT_NETWORK_TIMEZONE}`
      );
    }

    logger.debug(`Network timezone: ${setTimezone}`);
    Settings.defaultZone = setTimezone;

    get().refreshTime();
  },

  refreshTime() {
    const k = get().currentHour;
    const month = get().activeMonth;
    const year = get().activeYear;
    if (!month || !year || !k) return;
    const { lpMonth, lpYear } = {
      lpMonth: getLatestProcessedMonth(k, month),
      lpYear: getLatestProcessedYear(k, year),
    };
    const fetchedRange = {
      start: lpMonth.startOf("year").startOf("day"),
      end: lpMonth.endOf("day"),
    };
    set({ lpMonth, lpYear, fetchedRange }, undefined, "refreshTime");
  },

  getInfoBlock<BlockType>(
    colName: string,
    blocks: Record<string, any>,
    block: any,
    defaultValue: BlockType
  ): BlockType | null {
    try {
      const blockValue = getValueFromBlock(
        blocks,
        block.to_block_name(),
        get().selectedNetworkId,
        block.col[colName]
      );
      return blockValue || defaultValue;
    } catch (err) {
      logger.error("updateStoreByInfoBlock failed");
      console.error(err as Error);
      return defaultValue;
    }
  },

  registerChart: (chartId, ref) => {
    if (!chartId || !ref)
      logger.error("registerChart failed: both `chartId` and `ref` args are required");
    if (get().chartRefs?.[chartId]) return;
    logger.debug("Registering Chart Reference: ", chartId);
    set((state) => ({
      chartRefs: { ...state.chartRefs, [chartId]: ref },
    }));
  },
});

export default create<NetworkStoreSlice>()(
  zustandMiddlware(networkStore, {
    name: "networkStore",
  })
);
