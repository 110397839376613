import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { useInject } from "@hooks";
import { Section } from "@shared/ui/layout";

import EnergyProductionGraph from "./EnergyProductionGraph";
import {
  ComponentType,
  COOLING_PRODUCING_COMPONENTS,
  HEAT_PRODUCING_COMPONENTS,
} from "./Opt.const";
import ChargeDischargeGraph from "./Simulation/results/charts/ChargeDischargeGraph";
import ElectricityPriceGraph from "./Simulation/results/charts/ElectricityPriceGraph";
import ExternalConnectionGraph from "./Simulation/results/charts/ExternalConnectionGraph";
import MarginalCostGraph from "./Simulation/results/charts/MarginalCostGraph";
import StoredEnergyGraph from "./Simulation/results/charts/StoredEnergyGraph";
import WeatherGraphOpt from "./Simulation/results/charts/WeatherGraph";

export default function Charts({ results }) {
  const { t } = useTranslation(["production"]);
  const { preferences } = useInject("opt_store", "preferences");

  return results.map((result) => (
    <Grid xs={12} item rowSpacing={2} key={result.input_parameters.config_id}>
      {/* Heat Production Plan */}
      <Section
        title={t("text_heat_production_plan_district_heating_grid")}
        collapsible
        contentFull
        isCollapsed={preferences.getSimResultCollapsibleTab("heat_production_plan")}
        onCollapseToggleHandler={() =>
          preferences.upsertSimResultCollapsible("heat_production_plan")
        }
      >
        <Grid item xs={12} data-testid="opt-chart-heating-production-plan">
          <EnergyProductionGraph results={result} validTypes={HEAT_PRODUCING_COMPONENTS} />
        </Grid>
      </Section>

      <Section
        title={t("text_storage")}
        collapsible
        titleUpperCase
        isCollapsed={preferences.getSimResultCollapsibleTab("storage")}
        onCollapseToggleHandler={() => preferences.upsertSimResultCollapsible("storage")}
      >
        {/* Stored Energy */}
        <Grid item xs={12}>
          <StoredEnergyGraph results={result} />
        </Grid>
        {/* Charge Discharge */}
        <Grid item xs={12} mt={1}>
          <ChargeDischargeGraph results={result} />
        </Grid>
        <Grid item xs={12} mt={3} />
      </Section>

      {/* Electricity */}
      <Section
        title={t("text_electricity")}
        collapsible
        contentFull
        isCollapsed={preferences.getSimResultCollapsibleTab("electricity")}
        onCollapseToggleHandler={() => preferences.upsertSimResultCollapsible("electricity")}
      >
        <Grid item xs={12} testId="opt-chart-electricity">
          <ElectricityPriceGraph results={result} />
        </Grid>
      </Section>

      {/* External Connection */}
      {result.components.find((comp) => comp.type === ComponentType.EXTERNAL_CONNECTION) && (
        <Section
          title={t("text_external_connection")}
          collapsible
          contentFull
          isCollapsed={preferences.getSimResultCollapsibleTab("external_connection")}
          onCollapseToggleHandler={() =>
            preferences.upsertSimResultCollapsible("external_connection")
          }
        >
          <Grid
            item
            xs={12}
            testId="opt-chart-external-connection"
            className="opt-result-external-connection"
          >
            <ExternalConnectionGraph results={result} />
          </Grid>
        </Section>
      )}

      {/* Marginal Production Cost */}
      <Section
        title={t("text_variable_production_cost")}
        collapsible
        contentFull
        isCollapsed={preferences.getSimResultCollapsibleTab("variable_production_cost")}
        onCollapseToggleHandler={() =>
          preferences.upsertSimResultCollapsible("variable_production_cost")
        }
      >
        <Grid item xs={12} data-testid="opt-chart-variable-production-cost">
          <MarginalCostGraph results={result} />
        </Grid>
      </Section>

      {/* Weather */}
      <Section
        title={t("text_weather")}
        collapsible
        contentFull
        isCollapsed={preferences.getSimResultCollapsibleTab("weather")}
        onCollapseToggleHandler={() => preferences.upsertSimResultCollapsible("weather")}
      >
        <Grid item xs={12} data-testid="opt-chart-weather">
          <WeatherGraphOpt results={result} />
        </Grid>
      </Section>

      {/* Cooling Production Plan */}
      {result.components.find((comp) => comp.type === ComponentType.COOLING_DEMAND) && (
        <Grid item xs={12} className="opt-result-cooling-production-plan">
          <EnergyProductionGraph results={result} validTypes={COOLING_PRODUCING_COMPONENTS} />
        </Grid>
      )}
    </Grid>
  ));
}
