import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid, Paper } from "@mui/material";

import { logger as baseLogger } from "@core/logger";
import { getUrlParam, insertUrlParam, removeUrlParam } from "@core/utils";

import UtfErrorBoundary from "../ErrorBoundary";
import { UtfTab, UtfTabs } from "../UtfTab";

const logger = baseLogger.getSubLogger({ name: "<SectionSelector/>" });

export type Section = {
  id: string;
  title?: string;
  disabled?: boolean;
  permissions?: string[];
  component: FC;
  props?: Record<string, unknown>;
};

export type PermissionChecker = {
  haveAccess: (permissions: string[]) => boolean;
};

export type SectionSelectorProps = {
  selected?: string;
  sections: Section[];
  selectCallback?: (sectionId: string) => void;
  saveToRoute?: boolean;
  topOfPage?: boolean;
  routeSelector?: string;
  triggerSelectOnMount?: boolean;
  permissionChecker?: PermissionChecker;
  centered: boolean;
};

const defaultPermissionChecker = {
  haveAccess: (permissions) => true,
};

const isSectionAccessible = (
  section: Section,
  permissionChecker: PermissionChecker = defaultPermissionChecker
) => {
  return !section.disabled && permissionChecker.haveAccess(section.permissions || []);
};

const SectionSelectorBase: FC<SectionSelectorProps> = ({
  selected,
  selectCallback,
  sections = [],
  saveToRoute = false,
  topOfPage = false,
  routeSelector = "tab",
  triggerSelectOnMount = false,
  permissionChecker = defaultPermissionChecker,
  centered = false,
}) => {
  const ref = useRef<HTMLElement>();

  const accessibleSections = useMemo(
    () => sections.filter((section) => isSectionAccessible(section, permissionChecker)),
    [sections, permissionChecker]
  );

  const defaultSectionId = useMemo(() => {
    if (selected) {
      const selectedSection = sections.find((s) => s.id === selected);
      if (selectedSection && isSectionAccessible(selectedSection, permissionChecker)) {
        return selected;
      }
    }

    if (saveToRoute) {
      const routeSection = getUrlParam(routeSelector);
      const routeSectionObj = sections.find((s) => s.id === routeSection);
      if (
        routeSection &&
        routeSectionObj &&
        isSectionAccessible(routeSectionObj, permissionChecker)
      ) {
        return routeSection;
      }
    }

    return accessibleSections[0]?.id || "";
  }, [permissionChecker, routeSelector, saveToRoute, sections, selected, accessibleSections]);

  const [activeSectionId, setActiveSectionId] = useState(defaultSectionId);
  const isValidSection = useCallback(
    (sectionId: string) => sections.some((section) => section.id === sectionId),
    [sections]
  );

  // Trigger selectCallback on mount if triggerSelectOnMount is true
  useEffect(() => {
    if (triggerSelectOnMount && selectCallback) {
      selectCallback(defaultSectionId);
    }
  }, [triggerSelectOnMount, selectCallback, defaultSectionId]);

  useEffect(() => {
    setActiveSectionId(defaultSectionId);
  }, [defaultSectionId]);

  // Scroll to top on tab change
  useEffect(() => {
    if (!ref.current?.scrollTo) return;
    ref.current?.scrollTo(0, 0);
  }, [activeSectionId]);

  useEffect(() => {
    if (!isValidSection(activeSectionId)) return;

    if (saveToRoute) {
      insertUrlParam(routeSelector, activeSectionId);
    }
  }, [activeSectionId, isValidSection, routeSelector, saveToRoute]);

  useEffect(() => {
    return () => {
      if (saveToRoute) {
        removeUrlParam(routeSelector);
      }
    };
  }, [saveToRoute, routeSelector]);

  const onSectionChange = (_event: React.SyntheticEvent, newValue: string) => {
    console.log("SectionSelector onSectionChange:", {
      newValue,
      isValid: isValidSection(newValue),
    });
    if (isValidSection(newValue)) {
      setActiveSectionId(newValue);

      if (selectCallback) {
        console.log("SectionSelector calling selectCallback with:", newValue);
        selectCallback(newValue);
      }
    }
  };

  const ActiveSection = useMemo(() => {
    const section = sections.find((s) => s.id === activeSectionId);
    if (!sections || !section || !isSectionAccessible(section, permissionChecker)) {
      return NoSectionComponent;
    }
    return section.component;
  }, [activeSectionId, permissionChecker, sections]);

  return (
    <Grid container direction="column" wrap="nowrap" sx={{ height: "100%", width: "100%" }}>
      <Grid item>
        <Paper
          square
          elevation={2}
          sx={{
            zIndex: 2,
            position: "relative",
            backgroundColor: ({ palette }) => palette.primary.light,
          }}
        >
          <UtfTabs
            value={activeSectionId}
            onChange={onSectionChange}
            TabIndicatorProps={{ style: { background: "#ffff" } }}
            centered
          >
            {sections.map((option) => {
              const locked = !isSectionAccessible(option, permissionChecker);
              const disabled = option.disabled || locked;
              return (
                <UtfTab
                  key={option.id}
                  label={option.title}
                  value={option.id}
                  disabled={disabled}
                  isLocked={locked}
                />
              );
            })}
          </UtfTabs>
        </Paper>
      </Grid>
      <Grid item flexGrow={1} pb={1} sx={{ overflow: "hidden" }}>
        <Box
          data-testid={`section-content-${activeSectionId}`}
          ref={ref}
          sx={{
            height: "100%",
            display: topOfPage ? "block" : "flex",
            p: topOfPage ? 4 : 1,
            pl: 2,
            pr: 2,
            overflow: "auto",
            bgcolor: "background.hover",
            "& > *": {
              width: "100%",
            },
            flexDirection: "column",
          }}
        >
          <UtfErrorBoundary>
            <ActiveSection {...(sections.find((s) => s.id === activeSectionId)?.props || {})} />
          </UtfErrorBoundary>
          <Box py={2} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SectionSelectorBase;

function NoSectionComponent() {
  return <div>No section available</div>;
}
