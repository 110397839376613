/* eslint-disable i18next/no-literal-string */
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { SelectElement, SliderElement, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { Grid, TextField, Tooltip, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import {
  faBuilding,
  faCalculator,
  faCoin,
  faCube,
  faHeat,
  faQuestionCircle,
} from "@fortawesome/pro-light-svg-icons";
import { useDebouncedCallback } from "use-debounce";

import { AlternativeCostConfigForm, HeatPumpType } from "@customTypes/production/sales";

import alternativeCostStore from "@stores/AlternativeCost/AlternativeCost.store";
import { NetworkStore } from "@stores/networks";
import { useInject } from "@hooks";
import { FontAwesomeSvgIcon } from "@shared";

const sliderMarks = [
  {
    value: 60,
    label: "60%",
  },
  {
    value: 80,
    label: "80%",
  },
  {
    value: 100,
    label: "100%",
  },
];

type AltConstConfigFormProps = {
  configValues: AlternativeCostConfigForm;
  onChange: (values: AlternativeCostConfigForm) => void;
};

const buildingTypes = [{ label: "Custom building", id: "custom_building" }];

const radiatorDesignTemps = [
  { label: "60/40", id: "60/40" },
  { label: "50/30", id: "50/30" },
];

const heatPumpTypes: { label: HeatPumpType; id: HeatPumpType }[] = [
  { label: "Ground Source Heat Pump", id: "Ground Source Heat Pump" },
];

const peakPumpTypes = [{ label: "Electric", id: "Electric" }];

const ConfigureForm = ({ configValues, onChange }: AltConstConfigFormProps) => {
  const { t } = useTranslation(["sales"]);
  const theme = useTheme();

  const {
    alternativeCost,
    networks,
  }: {
    alternativeCost: typeof alternativeCostStore;
    networks: NetworkStore;
  } = useInject("alternativeCost", "networks");

  const currency = toJS(networks.currency);

  const calculatedValues = alternativeCost.use.calculatedValues();

  const { control: formControl, handleSubmit } = useForm<AlternativeCostConfigForm>({
    defaultValues: configValues,
  });

  const formValues = useWatch<AlternativeCostConfigForm>({
    control: formControl,
  });

  // We use a debounced function for calculating read only values. This fires onChange of the form
  // and updates the read only values in the store
  const calculateValues = useDebouncedCallback((values: AlternativeCostConfigForm) => {
    onChange(values);
  }, 500);

  useEffect(() => {
    calculateValues(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return (
    <form
      onSubmit={handleSubmit(() => console.log("Submit"))}
      style={{ width: "100%" }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={4} p={4} flexWrap="wrap" direction="row" width="100%">
        <Grid item xs>
          <Stack spacing={2} sx={{ width: "100%" }} minWidth={300}>
            {/* Power section */}
            <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
              <FontAwesomeSvgIcon icon={faHeat} />
              <Typography variant="h6" width="100%">
                {t("text_power")}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
              <FontAwesomeSvgIcon icon={faBuilding} />
              <SelectElement
                name="buildingType"
                label={t("text_building_type")}
                control={formControl}
                options={buildingTypes}
                fullWidth
                variant="standard"
              />
            </Stack>

            {/* Energy signature section */}
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography maxWidth={200}>{t("text_energy_signature")}</Typography>
              <TextFieldElement
                name="energySignatureA"
                label="a"
                control={formControl}
                type="number"
                fullWidth
                variant="standard"
              />
              <TextFieldElement
                name="energySignatureB"
                label="b"
                control={formControl}
                type="number"
                fullWidth
                variant="standard"
              />
              <TextFieldElement
                name="energySignatureT0"
                label="t0"
                control={formControl}
                type="number"
                fullWidth
                variant="standard"
                InputProps={{
                  endAdornment: <Typography>°C</Typography>,
                }}
              />
            </Stack>

            <Tooltip title={t("tooltip_energy_signature")} placement="right" arrow>
              <TextFieldElement
                name="heatedArea"
                label={t("text_heated_area")}
                control={formControl}
                type="number"
                fullWidth
                variant="standard"
                InputProps={{
                  endAdornment: <Typography>m²</Typography>,
                }}
              />
            </Tooltip>

            <SelectElement
              name="radiatorDesignTemp"
              label={t("text_radiator_design_temp")}
              control={formControl}
              options={radiatorDesignTemps}
              fullWidth
              variant="standard"
            />

            <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
              <FontAwesomeSvgIcon icon={faCube} />
              <SelectElement
                name="heatPumpType"
                label={t("text_heat_pump_type")}
                control={formControl}
                options={heatPumpTypes}
                fullWidth
                variant="standard"
              />
            </Stack>

            <Stack direction="row" justifyContent="start" alignItems="center" padding={2}>
              <SliderElement
                name="powerCoverage"
                label={t("text_power_covarage")}
                control={formControl}
                marks={sliderMarks}
                step={10}
                min={60}
                sx={{
                  "& .MuiSlider-thumb": {
                    width: "4px",
                    height: "44px",
                    borderRadius: "4px",
                    color: theme.palette.primary.main,
                  },
                  "& .MuiSlider-rail": {
                    color: theme.palette.secondary.main,
                    height: "16px",
                  },
                  "& .MuiSlider-track": {
                    color: theme.palette.secondary.main,
                    opacity: 0.3,
                  },
                  "& .MuiSlider-markLabel": {
                    color: theme.palette.primary.main,
                    fontSize: "12px",
                    paddingTop: "10px",
                  },
                  "& .MuiSlider-mark": {
                    color: theme.palette.primary.main,
                    width: "12px",
                    height: "10px",
                    marginLeft: "-5px",
                    borderRadius: "5px",
                  },
                }}
              />
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
              <FontAwesomeSvgIcon icon={faCube} />
              <SelectElement
                name="peakPumpType"
                label={t("text_peak_pump_type")}
                control={formControl}
                options={peakPumpTypes}
                fullWidth
                variant="standard"
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs>
          <Stack spacing={2} sx={{ width: "100%" }} minWidth={300}>
            {/* Calculated Metrics */}
            <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
              <FontAwesomeSvgIcon icon={faCalculator} />
              <Typography variant="h6" width="100%">
                {t("text_calculated_metrics")}
              </Typography>
            </Stack>
            {/* All of these are readonly from the store */}
            {calculatedValues && (
              <>
                <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
                  <TextField
                    label={t("text_hot_water_heat")}
                    value={calculatedValues.hotWaterHeatKwh}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      endAdornment: <Typography>kWh</Typography>,
                    }}
                  />
                  <FontAwesomeSvgIcon
                    icon={faQuestionCircle}
                    sx={{ color: theme.palette.primary.main, fontSize: 14 }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
                  <TextField
                    label={t("text_radiator_heat")}
                    value={calculatedValues.radiatorHeatKwh}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      readOnly: true,
                      endAdornment: <Typography>kWh</Typography>,
                    }}
                  />
                  <FontAwesomeSvgIcon
                    icon={faQuestionCircle}
                    sx={{ color: theme.palette.primary.main, fontSize: 14 }}
                  />
                </Stack>
                <TextField
                  label={t("text_hot_water_specific_heat")}
                  value={calculatedValues.hotWaterSpecificHeatKwh}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    endAdornment: <Typography>kWh/m²</Typography>,
                  }}
                />
                <TextField
                  label={t("text_radiator_specific_heat")}
                  value={calculatedValues.radiatorSpecificHeatKwh}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    endAdornment: <Typography>kWh/m²</Typography>,
                  }}
                />
                <TextField
                  label={t("text_max_heat_power")}
                  value={calculatedValues.maxHeatPower}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    endAdornment: <Typography>kW</Typography>,
                  }}
                />
                <TextField
                  label={t("text_installed_heat_pump_capacity")}
                  value={calculatedValues.installedHeatPumpCapacity}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    endAdornment: <Typography>kW</Typography>,
                  }}
                />
                <TextField
                  label={t("text_installed_peak_boiler_capacity")}
                  value={calculatedValues.installedPeakBoilerCapacity}
                  fullWidth
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    endAdornment: <Typography>kW</Typography>,
                  }}
                />
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs>
          <Stack spacing={2} sx={{ width: "100%" }} minWidth={300}>
            {/* Economics section */}
            <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
              <FontAwesomeSvgIcon icon={faCoin} />
              <Typography variant="h6" width="100%">
                {t("text_economics")}
              </Typography>
            </Stack>
            <TextFieldElement
              name="interestRate"
              label={t("text_interest_rate")}
              control={formControl}
              fullWidth
              variant="standard"
              InputProps={{
                endAdornment: <Typography>%</Typography>,
              }}
            />
            <TextFieldElement
              name="investmentCostHeatPump"
              label={t("text_investment_cost_heat_pump")}
              control={formControl}
              fullWidth
              variant="standard"
              InputProps={{
                endAdornment: <Typography>{currency}</Typography>,
              }}
            />
            <TextFieldElement
              name="investmentCostBorehole"
              label={t("text_investment_cost_borehole")}
              control={formControl}
              fullWidth
              variant="standard"
              InputProps={{
                endAdornment: <Typography>{currency}</Typography>,
              }}
            />
            <TextFieldElement
              name="investmentCostPeakBoiler"
              label={t("text_investment_cost_peak_boiler")}
              control={formControl}
              fullWidth
              variant="standard"
              InputProps={{
                endAdornment: <Typography>{currency}</Typography>,
              }}
            />
            <TextFieldElement
              name="heatPumpDepreciation"
              label={t("text_heat_pump_depreciation")}
              control={formControl}
              fullWidth
              variant="standard"
              InputProps={{
                endAdornment: <Typography>years</Typography>,
              }}
            />
            <TextFieldElement
              name="boreholeDepreciation"
              label={t("text_borehole_depreciation")}
              control={formControl}
              fullWidth
              variant="standard"
              InputProps={{
                endAdornment: <Typography>years</Typography>,
              }}
            />
            <TextFieldElement
              name="peakBoilerDepreciation"
              label={t("text_peak_boiler_depreciation")}
              control={formControl}
              fullWidth
              variant="standard"
              InputProps={{
                endAdornment: <Typography>years</Typography>,
              }}
            />
            <TextFieldElement
              name="omCost"
              label={t("text_o_m_cost")}
              control={formControl}
              fullWidth
              variant="standard"
              InputProps={{
                endAdornment: <Typography>{currency}/year</Typography>,
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      {/* <Box p={2} display="flex" justifyContent="center" width="100%">
        <Button type="submit" variant="contained" color="primary">
          {t("submit")}
        </Button>
      </Box> */}
    </form>
  );
};

export default ConfigureForm;
