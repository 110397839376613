import type { OptComponent, OptConfigSummary } from "@customTypes/production/optimization";

import { getRandomHexColor } from "@core/utils";
import {
  COMPONENT_TYPE_COLOR,
  COOLING_PRODUCING_COMPONENTS,
} from "@components/Optimization/Opt.const";

export function orderRowByCategory({
  row,
  orderBy,
}: NonNullable<{ row: [{ category: string }]; orderBy: string }>) {
  // order the given rowCategory to be in on top row of table
  row.some(
    (item: { category: string }, idx: number) =>
      item.category === orderBy && row.unshift(row.splice(idx, 1)[0])
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSummary(config: { kpis: any; components: any }): OptConfigSummary {
  const { kpis, components } = config;

  const summary: OptConfigSummary = {
    hasCoolingDemand: false,
    configName: "",
    electricitySoldRevenue: 0,
    totalRevenue: 0,
    totalHeatImport: 0,
    totalHeatExport: 0,
    productionCost: 0,
    totalHeatProduction: 0,
    totalCoolingProduction: 0,
    electricityGeneration: 0,
    netUnitCost: 0,
    electricityTotalUse: 0,
  };

  summary.hasCoolingDemand = !!components?.some((comp: OptComponent) => {
    const { type } = comp;
    if (!type) return false;
    return COOLING_PRODUCING_COMPONENTS.includes(type);
  });

  summary.electricitySoldRevenue = kpis.sold_electricity_revenue || 0;
  summary.totalRevenue = kpis.revenue || 0;
  summary.totalHeatImport = kpis.heat_imported || 0;
  summary.totalHeatExport = kpis.heat_exported || 0;
  summary.productionCost = kpis.cost || 0;
  summary.totalHeatProduction = kpis.heat_produced || 0;
  summary.totalCoolingProduction = kpis.cooling_produced || 0;
  summary.electricityGeneration = kpis.electricity_produced || 0;
  // Net cost per unit of energy = (production cost - rev sold electricity)/(heat generated + cooling generated)
  summary.netUnitCost =
    (summary.productionCost - summary.totalRevenue) /
    (summary.totalHeatProduction + summary.totalCoolingProduction + summary.totalHeatImport);

  summary.electricityTotalUse = kpis.electricity_consumed || 0;

  return summary;
}

export const getComponentColor = (component: OptComponent): string => {
  const { type, color } = component;
  if (color) return color;
  if (!type) return getRandomHexColor();

  return COMPONENT_TYPE_COLOR.get(type) ?? getRandomHexColor();
};
