import { AxiosInstance } from "axios";

import useAuthStore from "../stores/auth.store";

export class BaseApi {
  client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }
  async getAuthToken(): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useAuthStore.getState().getToken!();
  }
  get axiosDefaults() {
    return this.getAuthToken().then((token) => ({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }));
  }
}
