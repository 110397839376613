import { useTranslation } from "react-i18next";
import { TableBody, TableHead, TableRow } from "@mui/material";

import { Table, TableCell, TableContainer } from "@components/ui/InfoTable";
import { useInject } from "@hooks";
import { BaseCard } from "@shared/ui/analytics/cards";
import FUnitValue from "@shared/ui/analytics/FUnitValue";

export default function CostsAndRevenues({ results }) {
  const { t } = useTranslation(["production"]);
  const { networks } = useInject("networks");

  const colorArray = ["graph.blue", "graph.pink", "graph.teal"];

  return (
    <BaseCard title={t("text_costs_&_revenues")} titleAddon={networks.currency}>
      <TableContainer>
        <Table noBorder>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={1} noBorder />
              {results.map((result, index) => (
                <TableCell
                  colSpan={1}
                  noBorder
                  sx={{
                    color: colorArray[index],
                    width: "20%",
                  }}
                >
                  {result.summary.configName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{t("text_production_cost")}</TableCell>
              {results.map((result) => (
                <TableCell>
                  <FUnitValue num={result.summary.productionCost} unit={networks.currency} />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>{t("text_revenue_total")}</TableCell>
              {results.map((result) => (
                <TableCell>
                  <FUnitValue num={result.summary.totalRevenue} unit="SEK" />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>{t("text_revenue_sold_electricity")}</TableCell>
              {results.map((result) => (
                <TableCell>
                  <FUnitValue
                    num={result.summary.electricitySoldRevenue}
                    unit={networks.currency}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </BaseCard>
  );
}
