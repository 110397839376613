import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import { Alert, Box, Grid } from "@mui/material";
import PropTypes from "prop-types";

import HeatingResults from "@components/Optimization/Heating";
import { getSummary } from "@pages/Optimization/Opt.utils";
import { StateInputResult } from "@pages/Optimization/Simulation/results/components";
import { HeadingBar } from "@shared/ui/layout";

export default function OptimizationResults({
  results,
  components,
  loading = false,
  headerAddons,
  isComparing = false,
}) {
  const { t } = useTranslation(["production", "_action"]);

  // For every single result, we calculate the summary with getSummary()
  const updatedResults = results
    .filter((result) => result && Object.keys(result).length > 0)
    .map((result) => ({
      ...result,
      summary: getSummary(result),
    }));

  return (
    <Grid container spacing={2} data-testid="opt-operational-results-container">
      {updatedResults.length > 0 && (
        <>
          {headerAddons?.length > 0 && (
            <Grid item xs={12}>
              <HeadingBar
                title={t("text_simulation_result", { ns: "production" })}
                addons={headerAddons}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <HeatingResults results={updatedResults} loading={loading} isComparing={isComparing} />
          </Grid>
          {updatedResults[0].input_parameters.state_input ? (
            <Box px={2} width="100%">
              <StateInputResult
                stateInputs={toJS(updatedResults[0].input_parameters.state_input)}
                components={toJS(components)}
              />
            </Box>
          ) : (
            <Alert severity="warning">{t("text_no_state_input", { ns: "production" })}</Alert>
          )}
        </>
      )}
    </Grid>
  );
}

OptimizationResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      summary: PropTypes.shape({
        netUnitCost: PropTypes.number,
        totalHeatProduction: PropTypes.number,
        configName: PropTypes.string,
        productionCost: PropTypes.number,
        electricitySoldRevenue: PropTypes.number,
      }),
      name: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool,
  isComparing: PropTypes.bool,
  headerAddons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.node,
      action: PropTypes.node,
    })
  ),
  components: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      properties: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
    })
  ),
};
