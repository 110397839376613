import { useMemo } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { OptComponent } from "@customTypes/production/optimization";

import { useDefaultTableTheme } from "@shared/ui/analytics/tables/Table/utils";

type StateInput = {
  comp_id: number;
  prop_id: number;
  end_state: null;
  start_state: string;
};

type StateInputResultProps = {
  stateInputs: StateInput[];
  components: OptComponent[];
};

export default function StateInputResult({ stateInputs, components }: StateInputResultProps) {
  const data = useMemo(() => {
    if (!stateInputs?.length || !components?.length) return [];

    return stateInputs.map((stateInput) => {
      const component = components.find(
        (component) => Number(component.id) === stateInput.comp_id
      ) || {
        name: "Unknown/Deleted",
        properties: [
          {
            id: stateInput.prop_id,
            name: "Unknown/Deleted",
          },
        ],
      };
      const property = component?.properties?.find(
        (property) => property.id === stateInput.prop_id
      );
      return {
        component: component?.name || "Unknown",
        property: property?.name || "Unknown",
        start_state: stateInput.start_state || "-",
      };
    });
  }, [stateInputs, components]);

  const dataTable = useMaterialReactTable({
    ...useDefaultTableTheme({}, {}),
    columns: [
      {
        header: "Component",
        accessorKey: "component",
      },
      {
        header: "Property",
        accessorKey: "property",
      },
      {
        header: "Start state",
        accessorKey: "start_state",
      },
    ],
    data,
    initialState: {
      expanded: true,
      pagination: { pageIndex: 0, pageSize: 25 },
    },
  });

  return <MaterialReactTable table={dataTable} />;
}
