import { formatNumberForUnitMainLabel, isValue } from "@core/utils";

// eslint-disable-next-line import/no-default-export
export default function FUnitValue(props: { num: number | null; unit: string }) {
  if (isValue(props.num)) {
    const [num, exp, pre, unit] = formatNumberForUnitMainLabel(props.num, props.unit);
    return (
      <span>
        {num}
        {exp ? (
          <>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <span> x10</span>
            <sup>{exp}</sup>
          </>
        ) : null}{" "}
        {pre}
        {unit}
      </span>
    );
  }
  return null;
}
