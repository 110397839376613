/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { queryOptions } from "@tanstack/react-query";

import { OptComponent } from "@customTypes/production/optimization";

import OptimizationApi from "./optimization.api";

export const getNetworkCollectionsOptions = (
  api: OptimizationApi,
  networkId: string | undefined | null
) =>
  queryOptions({
    queryKey: ["alternativeCost", networkId, "collections"],
    queryFn: () => api.getCollections(networkId!),
    enabled: !!networkId,
  });

export const getConfigsForCollectionOptions = (
  api: OptimizationApi,
  networkId: string | undefined | null,
  collectionId: number | undefined | null
) =>
  queryOptions({
    queryKey: ["alternativeCost", networkId, "collection", collectionId],
    queryFn: () => api.getConfigsForCollection(networkId!, collectionId!),
    enabled: !!networkId && !!collectionId,
  });

export const getAltCostComponentTypes = (
  api: OptimizationApi,
  networkId: string | undefined | null
) =>
  queryOptions({
    queryKey: ["alternativeCost", networkId, "componentTypes"],
    queryFn: () => api.getAltCostComponentTypes(),
    enabled: !!networkId,
  });

export const getAltCostConfigComponents = (
  api: OptimizationApi,
  networkId: string | undefined | null,
  configId: number | undefined | null
) =>
  queryOptions({
    queryKey: ["alternativeCost", networkId, "config", configId, "components"],
    queryFn: () => api.getConfigComponents(networkId!, configId!),
    enabled: !!networkId && !!configId,
  });
