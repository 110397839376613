import { AxiosResponse } from "axios";

import { logger as baseLogger } from "@core/logger";
import { Network } from "@core/models";

import axiosClient from "../apiClient";
import { BaseApi } from "../baseApi";
import { useAuthStore } from "@core/stores";

const logger = baseLogger.getSubLogger({ name: "network.api" });

export class MdslApi {
  getAuthToken(): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useAuthStore.get.token() || "";
  }

  get axiosDefaults() {
    const token = this.getAuthToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  async registerUser(): Promise<AxiosResponse> {
    return axiosClient
      .post(
        "/users",
        {},
        {
          ...(await this.axiosDefaults),
          params: {
            max_management_level: 4,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => {
        //if 409, user already exists
        if (err.response.status === 409) {
          return;
        }
      });
  }

  async getNetworks(): Promise<Network[]> {
    const fetchNetworks = await axiosClient.get("/networks", await this.axiosDefaults);
    // if networks 401, we might not be authed on MDSL,
    // so we call registerUsers
    if (fetchNetworks.status === 401) {
      logger.error("Unauthorized /networks");
      logger.trace("Registering user to MDSL");
      const res = await this.registerUser();
      if (res) {
        logger.trace("User registered", res);
        return this.getNetworks();
      }
    }
    logger.trace("getNetworks", fetchNetworks.status);
    return fetchNetworks.data.data.collection;
  }
}
