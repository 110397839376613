import posthog from "posthog-js";

import { POSTHOG_ENABLED, UTF_ADMIN_FLAG } from "@config";
import { logger as baseLogger } from "@core";

let logger = baseLogger.getSubLogger({ name: "feature-flag" });
export const setLogger = (newLogger: typeof logger) => {
  logger = newLogger;
};

export const FLAG_LOAD_TIMEOUT = 5000; // 5 seconds timeout

type FeatureFlag = string;

type FeatureFlagState = {
  flagsLoaded: boolean;
  loadingPromise: Promise<void> | null;
  initialized: boolean;
};

// Export for testing
export const state: FeatureFlagState = {
  flagsLoaded: false,
  loadingPromise: null,
  initialized: false,
};

// Function to check if feature flags are loaded
export function areFeatureFlagsLoaded(): boolean {
  return state.flagsLoaded;
}

// Initialize feature flags loading
export function initializeFeatureFlags(): void {
  if (state.initialized) return;

  if (POSTHOG_ENABLED) {
    // Check if flags are already loaded
    if (posthog.getFeatureFlag(UTF_ADMIN_FLAG) !== null) {
      state.flagsLoaded = true;
    } else {
      posthog.onFeatureFlags(() => {
        state.flagsLoaded = true;
      });
    }
  }
  state.initialized = true;
}

// Initialize on module load
initializeFeatureFlags();

// Function to wait for feature flags to load
export function waitForFeatureFlags(): Promise<void> {
  if (state.flagsLoaded) return Promise.resolve();
  if (!POSTHOG_ENABLED) {
    state.flagsLoaded = true;
    return Promise.resolve();
  }

  if (!state.loadingPromise) {
    state.loadingPromise = Promise.race<void>([
      new Promise<void>((resolve) => {
        posthog.onFeatureFlags(() => {
          state.flagsLoaded = true;
          resolve();
        });
      }),
      new Promise<void>((_, reject) =>
        setTimeout(() => {
          logger.warn("Feature flag loading timed out");
          state.flagsLoaded = true; // Set loaded to prevent retries
          reject(new Error("Feature flag loading timeout"));
        }, FLAG_LOAD_TIMEOUT)
      ),
    ]).catch((error) => {
      logger.error("Error loading feature flags:", error);
      state.flagsLoaded = true; // Ensure we don't retry on error
      return;
    });
  }

  return state.loadingPromise || Promise.resolve();
}

export function isFeatureFlagOn(flagname: FeatureFlag): boolean {
  // First check environment variables
  const envEnabled = `${import.meta.env?.["REACT_APP_FEATURES"]}`.split(",").includes(flagname);
  if (envEnabled) {
    logger.debug(`[isFeatureFlagOn] ${flagname} enabled via env`);
    return true;
  }

  // Then check PostHog if it's enabled
  if (POSTHOG_ENABLED) {
    if (!state.flagsLoaded) {
      logger.warn(`[isFeatureFlagOn] ${flagname} checked before PostHog flags were loaded`);
      return false;
    }
    const isEnabled = posthog.isFeatureEnabled(flagname) ?? false;
    logger.debug(`[isFeatureFlagOn] ${flagname} PostHog status:`, isEnabled);
    return isEnabled;
  }

  logger.debug(`[isFeatureFlagOn] ${flagname} disabled (PostHog not enabled)`);
  return false;
}
