/* eslint-disable react/jsx-props-no-spreading */

import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";

import UtfErrorBoundary from "./ErrorBoundary";

const ACCORDION_HEADER_HEIGHT = 54;
const getBoxShadow = (segmented, shadows) => (segmented ? "none" : shadows[1]);

const getBorder = (segmented, theme) =>
  segmented ? `1px solid ${theme.palette.grey["200"]}` : "none";

const Accordion = styled(({ segmented, ...props }) => <MuiAccordion square {...props} />)(
  ({ theme, segmented }) => ({
    ...theme.typography.body2,
    color: segmented ? theme.palette.grey["800"] : "inherit",
    backgroundColor: theme.palette.grey["50"],
    boxShadow: getBoxShadow(segmented, theme.shadows),
    margin: "0 0 2px 0 !important",
    cursor: "default",
    textTransform: "none",
    "&.Mui-expanded": { margin: 0, border: "none" },
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  })
);

const AccordionSummary = styled(({ segmented, reversed, ...props }) => (
  <MuiAccordionSummary {...props} />
))(({ theme, segmented, reversed }) => ({
  ...(segmented ? {} : theme.typography.button),
  color: segmented ? "inherit" : theme.palette.primary.main,
  padding: 0,
  flexDirection: reversed ? "row-reverse" : "row",
  backgroundColor: theme.palette.grey["50"],
  boxShadow: getBoxShadow(segmented, theme.shadows),
  border: getBorder(segmented, theme),
  minHeight: `${ACCORDION_HEADER_HEIGHT}px`,
  height: `${ACCORDION_HEADER_HEIGHT}px`,
  textTransform: !segmented ? "uppercase" : "none",
  "& .MuiAccordionSummary-expandIconWrapper": {
    width: "4rem",
    height: "100%",
    marginRight: "-1px",
    "& > svg": {
      height: "100%",
      flex: 1,
      padding: "15px",
      color: theme.palette.primary.main,
    },
  },
  "& .MuiAccordionSummary-content": {
    height: "100%",
    lineHeight: `${ACCORDION_HEADER_HEIGHT}px`,
    "& > *": {
      borderLeft: getBorder(segmented, theme),
      padding: `0 ${theme.spacing(2)}`,
    },
  },
  "&.Mui-expanded": { minHeight: "auto" },
}));

const AccordionDetails = styled(({ segmented, ...props }) => <MuiAccordionDetails {...props} />)(
  ({ theme, segmented }) => ({
    ...theme.typography.body1,
    display: "flex",
    flexDirection: segmented ? "row" : "column",
    padding: segmented ? 0 : "20px 16px !important",
    boxShadow: getBoxShadow(segmented, theme.shadows),
    borderLeft: getBorder(segmented, theme),
    marginLeft: segmented ? "4rem" : 0,
    marginBottom: "0",
    "& > *": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: segmented ? theme.spacing(3) : 0,
      borderTop: getBorder(segmented, theme),
      borderRight: getBorder(segmented, theme),
      borderBottom: getBorder(segmented, theme),
    },
  })
);

export default function UtfAccordion({
  header,
  expanded = false,
  headerReversed = false,
  segmented = false,
  children,
  ...props
}) {
  const [isExpanded, setExpanded] = useState(expanded);

  const toggleAccordion = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Accordion expanded={isExpanded} segmented={segmented} {...props}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        reversed={headerReversed}
        segmented={segmented}
        onClick={toggleAccordion}
      >
        {header}
      </AccordionSummary>
      <AccordionDetails segmented={segmented}>
        <UtfErrorBoundary>{children}</UtfErrorBoundary>
      </AccordionDetails>
    </Accordion>
  );
}

UtfAccordion.defaultProps = {
  headerReversed: false,
  segmented: false,
  expanded: false,
  children: null,
};

UtfAccordion.propTypes = {
  ...MuiAccordion.propTypes,
  header: PropTypes.element.isRequired,
  headerReversed: PropTypes.bool,
  segmented: PropTypes.bool,
  expanded: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};
