import { flow } from "mobx";
import { createRouterState, RouterStore } from "mobx-state-router";

import { logger as baseLogger } from "@core/logger";
import Dash from "@pages/dash/store";
import DistDashStore from "@pages/Distribution/store";
import ForeCastStore from "@pages/ForeCast/Forecast.store";
import MeteringDashStore from "@pages/MeteringDash/Dashboard/store";
import { FaultDetectionStore } from "@pages/MeteringDash/FaultDetection/FaultDetection.store";
import OptimizationStore from "@pages/Optimization/Opt.store";
import PricingStore from "@pages/Pricing/store";
import PricingReportStore from "@pages/PricingReports/store";
import Production from "@pages/Production/store";
import SettingsStore from "@pages/Profile/Settings/store";
import ProfileStore from "@pages/Profile/store";
import RtAnalysis from "@pages/RTAnalysis/rtstore";
import { SalesStore } from "@pages/SalesDash/store";
import { ScenarioAnalysisStore } from "@pages/ScenarioAnalysis/ScenarioAnalysis.store";

import { storeRoutes } from "../routes";
import alternativeCostStore from "./AlternativeCost/AlternativeCost.store";
import Api from "./api";
import FaultDetectionApi from "./apis/FaultDetection/FaultDetection.api";
import OptimizationApi from "./apis/Optimization/optimization.api";
import { AppStore } from "./app";
import Cache from "./cache";
import { FiltersStore } from "./filters.store";
import InteractionStore from "./Interaction/interaction.store";
import LanguageStore from "./language.store";
import { NetworkStore } from "./networks";
import NotificationStore from "./Notification/notification.store";
import OptApi from "./opt_api";
import Session from "./session";
import { SubstationStore } from "./substation";
import UiStore from "./ui_store";
import UserPreferences from "./user_preferences";
import UtfApi from "./utfapi";

const notFound = createRouterState("notFound");

const USING_CACHE = ["networks"];

const logger = baseLogger.getSubLogger({ name: "rootStore" });

class RootStore {
  alternativeCost: typeof alternativeCostStore;
  constructor() {
    this.initialized = false;
    this.logger = logger; // will give all stores to access and extend for their use
    this.session = new Session(this);
    this.networks = new NetworkStore(this);
    this.preferences = new UserPreferences(this);

    this.cache = new Cache(this);
    this.filters = new FiltersStore(this);
    this.routerStore = new RouterStore(storeRoutes, notFound, { parent: this });
    this.ui = new UiStore(this);
    this.pricing = new PricingStore(this);
    this.preport = new PricingReportStore(this);

    this.sub = new SubstationStore(this);
    this.app = new AppStore(this);

    // UI and Interaction
    this.notifications = new NotificationStore(this);
    this.interaction = new InteractionStore(this);

    this.frcst = new ForeCastStore(this); //                    move to the page level context
    this.production = new Production(this); //                  move to the page level context
    this.rta = new RtAnalysis(this);
    this.language = new LanguageStore(this);
    this.faultDetection = new FaultDetectionStore(this);

    // APIs
    this.utfapi = new UtfApi(this); /* low level api access */
    this.newapi = new Api(this); /* high level api access */
    this.faultDetectionApi = new FaultDetectionApi(this);

    this.distdash = new DistDashStore(this); //                 move to the page level context
    this.auth = new ProfileStore(this);
    this.dash = new Dash(this); //                              move to the page level context
    this.sales = new SalesStore(this);
    this.meter = new MeteringDashStore(this); //                move to the page level context
    this.opt_api = new OptApi(this);
    this.opt_store = new OptimizationStore(this);
    this.settingsStore = new SettingsStore(this);
    this.scenario_store = new ScenarioAnalysisStore(this);
    this.alternativeCost = alternativeCostStore;

    // New, proper APIs
    this.optimizationApi = new OptimizationApi(this);

    this.startup = this.startup.bind(this);
  }

  startup = flow(function* rootStartup() {
    if (this.initialized) return;

    const isLoggedIn = yield this.session.checkSession();
    // Run below methods only for logged-in users
    if (!isLoggedIn) return;

    this.logger.debug("Starting up... First time run. Triggering init methods of child stores...");
    yield this.app.startup();
    yield this.networks.check();

    this.initialized = true;
    this.logger.debug("Stores initialized successfully!");
  });

  async clearCache() {
    USING_CACHE.forEach((store) => {
      this[store].clearCache();
    });
  }
}

const rootStoreInstance = window.mobxStore || new RootStore();

// Expose the Mobx store instance during E2E testing and Dev
// The reason its exposed on dev; to avoid Mobx crash on HMR updates
if (!window.mobxStore) {
  window.mobxStore = rootStoreInstance;
}

export { rootStoreInstance as rootStore };
