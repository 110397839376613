import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { DateTime } from "luxon";

import { isValue } from "@core/utils";
import { createSeriesToggleHandler } from "@core/utils/createSeriesToggleHandler";
import { HighchartsBase } from "@shared/ui/analytics/charts";

const HEIGHT = 600;
const GRAPH_COLORS = ["#bb4aaa", "#4ab7bb", "#d7bd4d"];

function WeatherGraph({ results }) {
  const { t } = useTranslation(["production"]);

  const [visibleSeries, setVisibleSeries] = useState({
    outdoorTemp: true,
    windSpeed: true,
    globalIrradiation: true,
  });

  function getNumberOnly(data: number[][] | null | undefined) {
    if (!data) return [];
    const newData = [];
    data.forEach((d) => newData.push(isValue(d[1]) ? d[1] : null));

    return newData;
  }

  const series = useMemo(() => {
    if (
      !results ||
      !results.weather_forecast ||
      !results.input_parameters ||
      !results.input_parameters.start_time
    )
      return [];

    const weatherData = results.weather_forecast;
    const startTime = DateTime.fromISO(results.input_parameters.start_time).toMillis();
    return [
      {
        type: "line",
        name: t("text_outdoor_temperature"),
        yAxis: 0,
        pointStart: startTime,
        pointInterval: 3600000,
        data: getNumberOnly(weatherData?.temperature),
        color: GRAPH_COLORS[0],
        threshold: 0,
        tooltip: {
          valueSuffix: " °C",
        },
        id: "outdoorTemp",
        visible: visibleSeries.outdoorTemp,
      },
      {
        type: "line",
        name: t("text_wind_speed"),
        yAxis: 1,
        pointStart: startTime,
        pointInterval: 3600000,
        data: getNumberOnly(weatherData?.wind_speed),
        color: GRAPH_COLORS[1],
        threshold: 0,
        tooltip: {
          valueSuffix: " m/s",
        },
        id: "windSpeed",
        visible: visibleSeries.windSpeed,
      },
      {
        type: "line",
        name: t("text_global_irradiation"),
        yAxis: 2,
        pointStart: startTime,
        pointInterval: 3600000,
        data: getNumberOnly(weatherData?.global_irradiation),
        color: GRAPH_COLORS[2],
        threshold: 0,
        tooltip: {
          valueSuffix: " W/m²",
        },
        id: "globalIrradiation",
        visible: visibleSeries.globalIrradiation,
      },
    ];
  }, [results, t, visibleSeries]);

  const yAxis = useMemo(
    () => [
      {
        title: {
          text: t("text_outdoor_temperature"),
          style: {
            color: GRAPH_COLORS[0],
          },
        },
        yUnit: "°C",
        labels: {
          style: {
            color: GRAPH_COLORS[0],
          },
        },
        plotLines: [
          {
            color: GRAPH_COLORS[0],
            width: 3,
            value: 0,
            zIndex: 5,
          },
        ],
        resize: {
          enabled: true,
        },
        visible: visibleSeries.outdoorTemp,
      },
      {
        gridLineWidth: 0,
        title: {
          text: t("text_wind_speed"),
          style: {
            color: GRAPH_COLORS[1],
          },
        },
        yUnit: "m/s",
        labels: {
          style: {
            color: GRAPH_COLORS[1],
          },
        },
        plotLines: [
          {
            color: GRAPH_COLORS[1],
            width: 3,
            value: 0,
            zIndex: 5,
          },
        ],
        resize: {
          enabled: true,
        },
        visible: visibleSeries.windSpeed,
      },
      {
        gridLineWidth: 0,
        title: {
          text: t("text_solar_irradiation"),
          style: {
            color: GRAPH_COLORS[2],
          },
        },
        yUnit: "W/m²",
        labels: {
          style: {
            color: GRAPH_COLORS[2],
          },
        },
        plotLines: [
          {
            color: GRAPH_COLORS[2],
            width: 3,
            value: 0,
            zIndex: 5,
          },
        ],
        resize: {
          enabled: true,
        },
        visible: visibleSeries.globalIrradiation,
      },
    ],
    [t, visibleSeries]
  );

  return (
    <HighchartsBase
      chart={{
        type: "line",
        height: HEIGHT,
        alignThresholds: true,
      }}
      xAxis={{
        title: {
          text: "Time",
        },
        type: "datetime",
      }}
      yAxis={yAxis}
      series={series}
      plotOptions={{
        series: {
          marker: false,
          stacking: "normal",
          events: {
            legendItemClick: createSeriesToggleHandler(setVisibleSeries),
          },
        },
      }}
      yAxisOptions={{
        resize: {
          enabled: true,
        },
      }}
      legend={{ enabled: true, layout: "horizontal", align: "center", verticalAlign: "bottom" }}
      disableBoost
    />
  );
}
export default observer(WeatherGraph);
